"use strict";

var moduleDependencies = [
    'pascalprecht.translate',
    "svb.services",
    "svb.filters"
];

angular.module("svb.footer", moduleDependencies)
    .config(require("./locales/locale.js"))
    .directive("svbFooter", require("./footerDirective.js"));

module.exports = "svb.footer";