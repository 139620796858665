import _ from 'lodash';
import firstBy from 'thenby';

const PAGINATION_SIMPLE_TOGGLE = 'simple-toggle';
const PAGINATION_PAGER = 'pager';

class TableController {
    constructor($q, $scope, $transclude, $parse, $timeout) {
        this.$q = $q;
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.curSortController = null;
        this.columns = [];
        this.wrappedData = [];
        this.canExpand = $transclude.isSlotFilled("details");
        this.$parse = $parse;
        this.options = this.options || {};

        /**
         * Number of rows per view
         * @type {number}
         */
        this.rowsPerPage = parseInt(this.rowsPerPage);
    }

    $onInit() {
        this.$scope.$watchCollection("tableCtrl.data", this.onDataChanged.bind(this));
        this.$scope.$watch("tableCtrl.currentPage", this._updatePaginationLabelButton.bind(this));

        // Check if rowsPerPage is number
        if (isNaN(this.rowsPerPage)) {
            switch (this.paginationStrategy) {
                case PAGINATION_PAGER:
                    this.rowsPerPage = 50;
                    break;
                case PAGINATION_SIMPLE_TOGGLE:
                default:
                    this.rowsPerPage = Infinity;
                    break;
            }
        }

        if (isNaN(this.currentPage)) {
            this.currentPage = 0;
        }

        // Override custom click callback if passed in
        this.onClick = (row, $event) => {
            // If a child element has a click event then don't execute this function.
            // The only time this parent element is called before the child is when the user presses space/enter and
            // this is due to the way ngAria works.
            if (!$event.target.isEqualNode($event.currentTarget) && $event.target.hasAttribute("ng-click")) {
                return;
            }

            if (this.options.onRowClick) {
                return this.options.onRowClick(row);
            }

            return this.toggleRow(row);
        };
    }

    onDataChanged(newData) {
        this.wrappedData = _.map(newData, (item) => ({
            expandedDetails: null,
            isCollapsed: true,
            model: item
        }));

        if (this.curSortController && !this.onSortCb) {
            // Resort if the underlying data changed when client side sorting is being used
            this.onSort(this.curSortController);
        }
    }

    /**
     * @returns {number}
     */
    get visibleRowsNumber() {
        switch (this.paginationStrategy) {
            case PAGINATION_SIMPLE_TOGGLE:
                return this.currentPage === 0 ? this.rowsPerPage : this.data.length;

            default:
                return (this.currentPage + 1) * this.rowsPerPage;
        }
    }

    /**
     * Update pagination action button
     * @returns {void}
     * @private
     */
    _updatePaginationLabelButton() {
        switch (this.paginationStrategy) {
            case PAGINATION_SIMPLE_TOGGLE: // eslint-disable-line no-case-declarations
                const isCollapsed = this.currentPage === 0;
                this.paginationActionClass = isCollapsed ? 'icon-arrow-down' : 'icon-arrow-up';
                break;

            default:
                this.paginationActionClass = 'icon-arrow-down';
                break;
        }
    }

    /**
     * Returns column controller with specifed label
     * @param {string} label - Column label
     * @returns {string}
     * @private
     */
    _getOriginalColumnSort(label, primaryOrder) {
        let column = _.find(this.columns, (column) => column.value === label);

        return column ? column.originalSort : primaryOrder;
    }

    /**
     * Builds comparator function for multiply fields sorting
     * @param {string} primaryColumn - Primary column to sort by
     * @param {string} primaryOrder - Sort order for primary column (asc / desc)
     * @returns {Function}
     * @private
     */
    _buildComparator(primaryColumn, primaryOrder = 'asc', comparator) {
        let thenBy = firstBy((item) => {
            return comparator ? comparator(item.model, primaryColumn) : this.$parse(primaryColumn)(item.model);
        }, {
            ignoreCase: true,
            direction: primaryOrder === 'asc' ? 1 : -1
        });

        if (this.sortOrder) {
            this.sortOrder.forEach((column) => {
                let direction = this._getOriginalColumnSort(column, primaryOrder) === 'asc' ? 1 : -1;
                let options = {
                    ignoreCase: true,
                    direction: direction
                };

                thenBy = thenBy.thenBy((item) => {
                    return this.$parse(column)(item.model);
                }, options);
            });
        }

        return thenBy;
    }

    /**
     * @param {ColumnController} sortColumnCtrl
     * @returns void
     */
    onSort(sortColumnCtrl) {
        this.curSortController = sortColumnCtrl;
        this.curSort = {
            colVal: this.curSortController.getRawValue(),
            direction: this.curSortController.sort
        };

        this.columns.forEach((colCtrl) => {
            if (this.curSortController !== colCtrl) {
                colCtrl.sort = undefined;
            } else {
                this.curSort.comparator = colCtrl.comparator;
            }
        });

        if (this.onSortCb) {
            // TODO Show spinner, error handling
            this.$timeout(() => {
                this.onSortCb(this.curSort);
            }, 0);
        } else {
            const comparator = this._buildComparator(this.curSort.colVal, this.curSort.direction, this.curSort.comparator);
            this.wrappedData = this.wrappedData.sort(comparator);
        }
    }

    onPaginate(shouldOpen) {
        let nextState = shouldOpen;

        if (shouldOpen === undefined) {
            nextState = this.currentPage === 0 ? 1 : 0;
        }

        switch (this.paginationStrategy) {
            case PAGINATION_SIMPLE_TOGGLE:
                this.currentPage = nextState;
                break;
        }

        const {currentPage, visibleRowsNumber} = this;

        if (this.onPaginateCallback) {
            this.onPaginateCallback({currentPage, visibleRowsNumber});
        }
    }

    hasData() {
        return this.data && this.data.length > 0;
    }

    hasSubHeaderColumns() {
        if (this.options && this.options.columns) {
            return _.some(this.options.columns, (columnOption) => {
                return !!columnOption.subHeaderTemplateUrl;
            });
        }

        return false;
    }

    hasCellTemplateUrl(column) {
        return !column.cellTemplateUrl;
    }

    shouldRenderUsingColumn() {
        if (this.options) {
            return !this.options.columns;
        }

        return true;
    }

    /**
     * @param {} row
     * @returns void
     */
    toggleRow(row) {
        if (!this.canExpand) {
            return;
        }

        // Collapse other rows if required
        if (this.canExpandMultipleRows === 'false') {
            this.wrappedData
                .filter((item) => item !== row)
                .forEach((item) => {
                    item.isCollapsed = true;
                });
        }

        var promise = this.$q.resolve(row);

        if (this.onExpand && !row.expandedDetails) {
            // TODO Show spinner, error handling
            promise = this.onExpand({
                row: row.model
            }).then((val) => {
                row.expandedDetails = val;
            });
        }

        promise.then(() => {
            row.isCollapsed = !row.isCollapsed;
        });
    }

    /**
     * @param {ColumnController} columnController
     * @returns void
     */
    addColumn(columnController) {
        this.columns.push(columnController);
    }

    getPagerDetailsString() {
        let startNum = (this.currentPage - 1) * this.rowsPerPage + 1;
        let endNum = Math.min(this.currentPage * this.rowsPerPage, this.totalItems);
        return `${startNum} - ${endNum} of ${this.totalItems}`;
    }
}

function table(directiveValidationService) {
    return {
        restrict: 'E',
        scope: {
            // Array of data that should be displayed in the table
            data: '=data',

            // Hash|Array of context that could be shared of cell rendering
            context: '=?context',

            // Array of data that should be displayed in the table
            options: '=?options',

            // Sort order array (optional)
            sortOrder: '=?sortOrder',

            // Number of rows per view (one-way, optional)
            rowsPerPage: '=?rowsPerPage',

            // Allow to expand more than one row in same time
            canExpandMultipleRows: '@?canExpandMultipleRows',

            // Pagination behaviour (one-way, optional) ('pager', 'simple-toggle')
            paginationStrategy: '@?paginationStrategy',

            // Optional callback that is invoked the first time a row is expanded. This
            // allows the consumer to fetch data asynchronously which is necessary to render
            // the expanded details section. The callback should return a promise that resolves
            // to the data used by the template
            onExpand: '&?onExpand',

            // Optional callback that is invoked when the sort changes in the table. This allows
            // the consumer to perform server side sorting. The callback should return a promise
            // that resolves when the data has been sorted. The consumer is expected to update
            // the 'data' binding with the newly sorted data.
            onSortCb: '&?onSort',

            // Callback for pagination change (callback, optional)
            onPaginateCallback: '&?onPaginate',

            // Optional translation key that will be used to display a message when the table has
            // no data. If no key is found, the default message will be displayed, "No Data Available".
            noResultKey: '@',

            // Optional bind param the consumer can use to view the current sort of the table.
            // This param is read only
            curSort: '=?',

            // Optional bind param the consumer can use to view or set the current page
            currentPage: '=?curPage',

            // Optional bind param for the total number of items. Required when using the 'pager' strategy
            totalItems: '=?'
        },
        templateUrl: 'core/modules/widgets/table/table.html',
        transclude: {
            columns: '?svbColumns',
            details: '?svbExpandedDetails'
        },
        controller: TableController,
        controllerAs: 'tableCtrl',
        bindToController: true,
        link ($scope, $element, $attrs) {
            directiveValidationService.enforceRequiredAttributes('svbTable', $attrs, 'data');
        }
    };
}

export default angular
    .module('svb-table', [
        require('./cell/cell'),
        require('./column/column'),
        require('../../services/servicesModule'),
        require('angular-ui-bootstrap')
    ])
    .config(require('./locales/locale.js'))
    .directive('svbTable', table)
    .name;
