"use strict";

// Directive used to display a "my profile" hover button
module.exports = function () {
    return {
        scope: {
            firstName: "=",
            lastName: "=",
            links: "="
        },
        templateUrl: "core/modules/navbar/profile/profile.html"
    };
};