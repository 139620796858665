/**
 * Custom datepicker with boiler plate code
 *
 * Example:
 *
 *  <svb-datepicker
 *      date="'11/30/2016'" OR date="'today'" | 'null' by default
 *      customClass="'id123456789'" | isn't passed by default
 *      placeholderKey="svb.date.select"
 *      allow-past-dates="false" | allows to select past dates. 'false' by default
 *      min-allowed-date="" | accept Date object, set the minimal selectable date
 *      is-always-populated="false"> | disallows to get date input field empty. 'false' by default
 *      date-disabled="function ({date, mode}) ... | custom method that disables dates
 *  </svb-datepicker>
 */

let _ = require('lodash');
let moment = require("moment-timezone");
let DAYS_CONST;

class Datepicker {
	constructor(DAYS, $element) {
		'ngInject';
		DAYS_CONST = DAYS;
		this.$element = $element;
	}

	$onInit() {
		this.onKeyup = this.onKeyup.bind(this);
		this.clearFn = this.clear.bind(this);

		this.popupOpened = false;
		this.isFocused = false;
		this.format = 'MM/dd/yyyy';
		this.isInvalid = false;
		this.altInputFormats = ['M!/d!/yyyy', 'MMddyyyy', 'M!/d!/yy'];
		this.placeholderKey = this.placeholderKey || "svb.datepicker.placeholder";
		this.showErrorUntouched = this.showErrorUntouched || false;

		if (this.initDate) {
			this.date = new Date(this.initDate);
		}

		this.errorObj = this.ngModel.$error;
		this.fieldName = this.ngModel.$name;

		$("body").on("keyup", this.onKeyup);

		this.setOptions();
	}

	$onDestroy() {
		$("body").off("keyup", this.onKeyup);
	}

	shouldShowErrors() {
		return this.ngModel.$dirty && this.ngModel.$invalid;
	}

	setOptions() {
		this.dateOptions = {
			formatYear: 'yyyy',
			formatDay: 'd',
			startingDay: DAYS_CONST.SUNDAY,
			maxMode: 'day',
			minMode: 'day',
			showWeeks: false,
			dateDisabled: this.dateDisabled || this.defaultDisableDate,
			customClass: this.getDayClass,
			minDate: this.setMinAllowedDate(),
			maxDate: this.maxAllowedDate
		};
	}

	onBlurFormat() {
		this.isFocused = false;
		let formattedDate = moment(this.date, ["MM/DD/YYYY", "MMDDYYYY"]);

		this.ngModel.$setDirty();
		this.ngModel.$setValidity('invalidDate', null);
		this.isInvalid = false;

		if (!this.date || !formattedDate.isValid()) {
			return this.validateDate();
		}

		this.date = formattedDate.toDate();
		if (this.callback) {
			this.callback();
		}
	}

	validateDate() {
		let inputFieldDateValue = this.$element.find("input").val();

		if (this.date && this.date.length > 0) {
			this.date = null;
		}

		if ("" !== inputFieldDateValue) {
			this.ngModel.$setValidity('invalidDate', false);
			this.isInvalid = true;
		}
	}

	setMinAllowedDate() {
		// minAllowedDate flag must have priority over allowPastDates
		if (this.minAllowedDate) {
			return this.minAllowedDate;
		}

		// allowed today and past dates
		if (!this.allowPastDates) {
			return new Date();
		}

		// any date is allowed
		return null;
	}

	modelOptions() {
		return {
			allowInvalid: true,
			timezone: "utc"
		};
	}

	open() {
		this.isFocused = true;
		if (this.onOpen) {
			this.onOpen().then(() => {
				this.setOptions();
				this.popupOpened = true;
			});
		} else {
			this.setOptions();
			this.popupOpened = true;
		}
	}

	clear() {
		if (this.isAlwaysPopulated) {
			this.date = this.date || this.minAllowedDate || new Date();
			return;
		}

		this.ngModel.$setDirty();
		this.ngModel.$setValidity('invalidDate', null);

		this.date = null;
		this.isInvalid = false;
	}

	getDayClass({date, mode}) { // NOSONAR (relax "Functions should not be too complex")
		let out = [],
			todayDate = new Date(),
			activeDate = moment(this.datepicker.activeDate);

		if (mode === 'day' && _.isDate(date)) {
			out.push('date-' + (date.getFullYear() - 2000) + '-' + (date.getMonth() + 1) + '-' + date.getDate());

			if (date.getDay() === DAYS_CONST.SATURDAY || date.getDay() === DAYS_CONST.SUNDAY) {
				out.push('datepicker-date-weekend');
			}

			if (date.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
				out.push('datepicker-date-today');
			}

			if (date.getMonth() !== activeDate.month() || date.getFullYear() !== activeDate.year()) {
				if (activeDate.isAfter(date)) {
					out.push("datepicker-previous-month");
				} else {
					out.push("datepicker-next-month");
				}
			}
		}

		return out.join(' ');
	}

	// Closes the date picker when the user tabs out of the input and popup elements
	onKeyup(event) {
		if (event.which === 9) {
			// Tab key pressed
			if (this.hasTabbedOutOfDatepicker()) {
				this.$element.scope().$apply(() => {
					this.popupOpened = false;
				});
			}
		}
	}

	hasTabbedOutOfDatepicker() {
		let node = document.activeElement;
		while (node !== null) {
			if ($(node).hasClass("uib-datepicker-popup") || node === this.$element[0]) {
				// Focus is still within the datepicker
				return false;
			}

			node = node.parentNode;
		}
		this.isFocused = false;
		return true;
	}

	defaultDisableDate() {
		return false;
	}
}

module.exports = {
	restrict: "E",
	require: {
		ngModel: '^ngModel'
	},
	templateUrl: "core/modules/formElements/datepicker/datepicker.html",
	controller: Datepicker,
	bindings: {
		date: "=ngModel",
		initDate: "=date",
		customClass: "@",
		callback: "=?",
		placeholderKey: "<?",
		allowPastDates: "<",
		minAllowedDate: "=",
		maxAllowedDate: '=',
		isAlwaysPopulated: "<",
		dateDisabled: "<",
		onOpen: "<",
		disableSelection: "<",
		isFocused: "=",
		isInvalid: "=?",
		clearFn: "=?"
	}
};