"use strict";

var moduleName = "svb-csrf-interceptor-helper";

function csrfInterceptorHelperFactory($injector, $q, csrfService) {
    return {
        tryWithNewToken: tryWithNewToken
    };

    function tryWithNewToken(rejection) {
        var config = rejection.config;

        if (config.isResubmitted) {
            config.isResubmitted = false;
            config.statusText = 'Failed to submit a request!';
            return $q.reject(rejection);
        }

        config.isResubmitted = true;

        return csrfService.refreshToken().then(function () {
            return $injector.get('$http')(config);
        });
    }
}

angular.module(moduleName, []).factory("csrfInterceptorHelper", ["$injector", "$q", "csrfService", csrfInterceptorHelperFactory]);

module.exports = moduleName;