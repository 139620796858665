"use strict";
var _ = require("lodash");
var config = require("../../../config.js");

module.exports = function () {
    var that = this;
    var newConfigs = global.envConfig || {};

    // Set default configs
    that._configObject = _.merge(config, newConfigs);

    return {
        // Call when application wants to override a default configuration
        setConfig: function (configObject) {
            _.merge(that._configObject, configObject);
        },
        getConfig: function () {
            return that._configObject;
        }
    };
};