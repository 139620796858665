"use strict";

let UAParser = require("ua-parser-js")();
let moduleName = "svb-mobile-check-service";

function mobileCheckService() {
    return {
        isMobile: function () {
            let device = UAParser.device;

			return device.type === "mobile" || device.type === "tablet";
        }
    };
}

angular.module(moduleName, [])
    .factory("mobileCheckService", [mobileCheckService]);

module.exports = mobileCheckService;
