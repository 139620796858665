module.exports = {
    "svb.authentication.required": "Authentication Required",
    "svb.authentication.enterCode": "Enter Authenticate Code",
    "svb.authentication.step": "Step {{x}} of {{n}}",
    "svb.authentication.confirmIdentity": "We now need to confirm your identity.",
    "svb.authentication.selectPhoneNumber": "Please select a phone number below to help us do so.",
    "svb.authentication.selectPhoneNumberOrPush": "Please select a phone number or push notification below to help us do so.",
    "svb.authentication.textMessageAssistance": "If you are unreachable at any of the above numbers, contact your company's online banking administrator and ask them to edit your user profile to update the Authenticate phone number.",
    "svb.authentication.textMessageAgreement": "If you select to receive a one-time passcode via SMS text message, SVB will send those text messages using an automated dialing system. As such, the mobile phone number(s) you provide are neither reviewed nor verified by SVB prior to or following activation of the service. (For US only: You certify that for the registered telephone or mobile device(s) referenced below, you 1) are the primary account holder, or 2) have been granted permission by the primary account holder.)",
	"svb.authentication.textMessageRates": "Please note, it is your responsibility to determine if your mobile service provider supports text messaging and your telephone or other mobile device is capable of receiving text messages. It’s also important to note that you are responsible for any and all fees and charges imposed by your cellular or mobile phone service and internet service provider. (For US only: You acknowledge and agree that your receipt of a one-time pass-code may be delayed or prevented by factor(s) affecting your cellular phone service provider, internet service provider(s) and other factors outside SVB’s control.)",
	"svb.authentication.textAcknowledge": "You acknowledge and agree that your receipt of a one-time pass-code may be delayed or prevented by factor(s) affecting your cellular phone service provider, internet service provider(s) and other factors outside SVB's control.",
	"svb.authentication.pushMessage": "You must download the SVB Mobile Banking App to receive push notifications on your mobile device(s). The push notifications will be sent to every mobile device(s) on which you have downloaded the SVB Mobile App, enabled notifications, and logged in at least once.",
	"svb.authentication.helpLinks": "Please {{infoLink}} for more information or contact SVB's {{supportLink}} for immediate assistance.",
    "svb.authentication.helpLinksUpdated": "Please {{infoLink}} for more information about SVB Authenticate or contact SVB's {{supportLink}} for immediate assistance.",
    "svb.authentication.supportCenter": "Client Support Center",
    "svb.authentication.textMe": "Text Me",
    "svb.authentication.callMe": "Call Me",
    "svb.authentication.or": "Or",
    "svb.authentication.notAtNumber": "I'm not at one of these numbers.",
    "svb.authentication.adminActivate": "Have Bank Administrator Activate My Updates",
    "svb.authentication.weAreCalling": "We are calling {{name}} at {{number}}.",
    "svb.authentication.thirtySeconds": "This might take up to 30 seconds.",
    "svb.authentication.speakIntoPhone": "When prompted, speak or enter {{number}} into the phone.",
    "svb.authentication.callCancel": "If you can't take this call right now, please {{link}} to cancel.",
    "svb.authentication.textCancel": "If you can't complete verification at this time, please {{link}} to cancel.",
    "svb.authentication.phoneStillRings": "If your phone still rings, don't answer it. The authentication process will stop and you'll be returned to your Online Banking session.",
    "svb.authentication.sentTextMessage": "We sent a text message to {{name}} at {{number}} with a One Time Code.",
    "svb.authentication.sixDigitsLong": "Enter your One Time Code",
    "svb.authentication.didntGetCode": "Didn't get the code?",
    "svb.authentication.authenticate": "Authenticate",
    "svb.authentication.authenticating": "Authenticating",
    "svb.authentication.texting": "Sending Text",
    "svb.authentication.calling": "Calling Now",
    "svb.authentication.reviewSecurityPreferences": "If you would like to review and manage your phone numbers {{link}} to be directed to your Security Preferences.",
    "svb.authentication.errorOccurred": "Oops, the following error(s) occurred:",
    "svb.authentication.trouble": "Having Trouble?",
    "svb.authentication.max": "Maximum Attempts Allowed Exceeded",
    "svb.authentication.failedToAuthenticate": "We see you've failed to successfully verify your identity multiple times. You will not be able to gain access to certain secure features within Online Banking until successful authentication is complete.",
    "svb.authentication.failedToAuthenticateMax": "We see you've failed to successfully verify your identity multiple times. Contact {{link}} immediately for assistance with logging on to SVB Online Banking.",
    "svb.authentication.systemError": "System error.",
    "svb.authentication.pleaseRetry": "We're sorry. We're having difficulty verifying you. Please try again.",
    "svb.authentication.contactClientServices": "Please contact {{link}} for immediate assistance.",
    "svb.authentication.clientServices": "Client Services",
    "svb.authentication.continueBanking": "Continue to Online Banking",
    "svb.authentication.logout": "Logout",
    "svb.authentication.successMessage": "You are now Authenticated",
    "svb.generic.clickHere": "click here",
    "svb.generic.refresh": "refresh",
	"svb.authentication.pushNotificationMessage": "To the SVB mobile app on all your devices",
	"svb.authentication.reviewSecurityPreferencesForPush": "If you do not receive the One-Time Code via the SVB Mobile App on your mobile device(s), please ensure that you have downloaded the SVB Mobile Banking App, turned on the push notifications option and logged in with your User Credentials.",
	"svb.authentication.sentPushMessage": "We have sent a <b>Push Notification</b> with a One Time Code, to every device where you have downloaded the SVB Mobile App and logged in successfully at least once."
};
