"use strict";

var moduleName = "svb-action-modal";

function ActionModalController($scope, $transclude, $timeout) {
    this.isSlotFilled = $transclude.isSlotFilled;

    this.init = function () {
        this.closeCallback = this.onCloseAction || $scope.$parent.$dismiss;
        this.onClose = (options) => {
            $timeout(this.closeCallback.call(this, options));
        };

        this.secondaryCallback = this.onSecondaryAction || $scope.$parent.$dismiss;
        this.onSecondary = (options) => {
            $timeout(this.secondaryCallback.call(this, options));
        };

        this.defaultCallBack = this.onDefaultAction || $scope.$parent.$close;
        this.onDefault = (options) => {
            $timeout(this.defaultCallBack.call(this, options));
        };

        if (!isNaN(parseInt(this.autoDismiss))) {
            this.timer = $timeout(() => {
                this.onClose({dismissedFromCloseAction: true});
            }, parseInt(this.autoDismiss));
        }

        $scope.$on('$destroy', () => {
            this.destroy();
        });
    };

    this.destroy = function () {
        $timeout.cancel(this.timer);
    };

    this.init();
}

function directive(directiveValidationService) {
    return {
        restrict: "E",
        scope: {
            titleKey: "@", // i18n key for the modal title
            defaultActionKey: "@", //  i18n key for default button. Defaults to "Submit"
            secondaryActionKey: "@", // i18n key for the secondary button. Defaults to "Cancel"
            onCloseAction: "&?", // Callback for when the X button is clicked. Defaults to modal $dismiss
            onDefaultAction: "&?", // Callback for when the default button is clicked. Defaults to modal $close
            onSecondaryAction: "&?", // Callback for when the secondary button is clicked. Defaults to $dismiss
            isDisabled: "=", // Whether or not the default button is disabled
            appearDisabled: "=", // Whether or not the default button should appear disabled but still be clickable
            showSpinner: "=", // Whether or not the spinner should display
            noButtons: "=", // When true, the footer with buttons will not be rendered
            autoDismiss: "@" // When specified, the modal will automatically dismissed after this amount of milliseconds
        },
        transclude: {
            'body': '?modalBody', // Allows you to specify custom modal content
            'buttons': '?modalButtons', // Allows you to provide your own custom buttons if the default ones won't work
            'icon': '?modalIcon' // Allows you to provide an icon in the title bar
        },
        templateUrl: "core/modules/widgets/actionModal/actionModal.html",
        controller: ["$scope", "$transclude", "$timeout", ActionModalController],
        controllerAs: 'vm',
        bindToController: true,
        link: function ($scope, $element, $attrs) {
            directiveValidationService.enforceRequiredAttributes("svbActionModal", $attrs, "titleKey");

            ["$close", "$dismiss"].forEach(function (name) {
                if (!$scope.$parent[name]) {
                    throw new Error("svbActionModal could not find '" + name + "' in the parent scope");
                }
            });
        }
    };
}

angular.module(moduleName, [
    require("angular-ui-bootstrap"),
    require("angular-translate"),
    require("../../services/servicesModule")
]).config(require("./locales/locale.js"))
    .directive("svbActionModal", [
        'directiveValidationService',
        directive
    ]);

module.exports = moduleName;