"use strict";

const moduleName = "svb.widgets";

const moduleDependencies = [
    require('./table/table'),
    require('./pagination/pagination'),
    require('./actionModal/actionModal'),
    require('./errorModal/errorModal'),
    require('./responsiveLabel'),
    require("./expandSection/expandSection"),
    require('./approveRejectTable/approveRejectTable'),
    require("./currency/currency"),
	require("./typeahead/typeahead")
];

angular.module(moduleName, moduleDependencies)
    .component('responsiveCollapse', require('./responsiveCollapse/responsiveCollapseComponent'))
    .component('svbTabs', require('./tabs/tabsComponent'))
    .component('svbTabsPanel', require('./tabs/tabsPanelComponent'))
    .component('svbPropertyPlaceholder', require('./propertyPlaceholder'));

module.exports = moduleName;