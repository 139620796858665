"use strict";

let _ = require('lodash');

let PROFILE_QUICKLINKS_ID = 6001;

function parseProfileLinks(nav) {
	let myProfile = nav.filter(function (navItem) {
		// Only the My Profile tab will send its ID
		return navItem.properties.menuId === PROFILE_QUICKLINKS_ID;
	});

	// Remove the My Profile links from the nav list
	if (myProfile.length > 0) {
		nav.splice(nav.indexOf(myProfile[0]), 1);
	}

	return {
		navigation: nav,
		myProfile: myProfile[0]
	};
}

module.exports = ["$http", "urlService", '$log', function ($http, urlService, $log) {
	let entitlements = [];

	const widgetPrefMurEntitlementMapping = [
		{ widget: "ACCOUNT_WATCH", entitlement: "accounts.summary" },
		{ widget: "CARD_ADMIN", entitlement: "cardAdminUser" },
		{ widget: "ACTION_ITEMS", entitlement: "wires.status" },
		{ widget: "ACTION_ITEMS", entitlement: "wires.approve" },
		{ widget: "CREDIT_CARDS", entitlement: "creditCard" },
		{ widget: "UK_SERVICES", entitlement: "UK User" }];

	const widgetPrefEcEntitlementMapping = [
		{ widget: "ACCOUNT_WATCH", entitlement: "accounts.summary" },
		{ widget: "ACTION_ITEMS", entitlement: "wires.status" },
		{ widget: "ACTION_ITEMS", entitlement: "wires.approve" },
		{ widget: "CREDIT_CARDS", entitlement: "creditCard" }];

	let validateEcEntitlement = (userData) => {
		let keys = userData.split(".");
		let curEnt = entitlements;

		for (let i = 0; i < keys.length; i++) {
			if (curEnt[keys[i]]) {
				curEnt = curEnt[keys[i]];
			} else {
				return false;
			}
		}

		return true;
	};

	return {
		get: function ($rootScope) {
			let url = urlService.getUrlForResource("headerData");

			return $http.get(url, { cache: true }).then(function (res) {
				if (res && res.status === 200 && res.data && res.data.data) {
					let headerData = res.data.data;
					let links = parseProfileLinks(headerData.navigation);

					entitlements = headerData.entitlements;
					if($rootScope){
						$rootScope.headerData = headerData;
					}
					return {
						error: null,
						name: headerData.name,
						lastLogin: headerData.profile.lastLoginDate,
						entitlements: headerData.entitlements,
						navigation: links.navigation,
						myProfile: links.myProfile,
						frequentLinks: headerData.frequentLinks,
						cardUser: headerData.cardUser,
						meta: headerData.meta,
						currentTime: headerData.serverTime,
						migration: headerData.migration
					};
				} else {
					$log.error('CANNOT RETRIEVE HEADER DATA from url ' + url);
					return {
						error: "no data",
						name: "",
						lastLogin: null,
						entitlements: null,
						navigation: [],
						myProfile: {},
						frequentLinks: [],
						cardUser: {},
						meta: null,
						currentTime: null,
						url: url
					};
				}
			});
		},
		isEcEntitled: function (widgetName) {
			let isEcEntitled = false;
			let mappedWidgets = _.filter(widgetPrefMurEntitlementMapping, (mapping) => {
				return mapping.widget === widgetName;
			});

			if (mappedWidgets && mappedWidgets.length > 0) {
				_.forEach(mappedWidgets, (mappedWidget) => {
					if (validateEcEntitlement(mappedWidget.entitlement)) {
						isEcEntitled = true;
						return false;
					}
				});
			}
			return isEcEntitled;
		},
		getEcEntitledWidgets: function () {
			let entitledWidgets = widgetPrefEcEntitlementMapping.filter((widgetEntitlement) => {
				return this.isEcEntitled(widgetEntitlement.widget);
			}).map((widgetEntitlement) => {
				return { "name": widgetEntitlement.widget };
			});

			return _.uniqBy(entitledWidgets, 'name');
		}
	};
}];
