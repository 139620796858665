module.exports = {
    "svb.navbar.search": "svb.navbar.search",
    "svb.navbar.contactUs": "svb.navbar.contactUs",
    "svb.navbar.myProfile": "svb.navbar.myProfile",
    "svb.navbar.mySecurity": "svb.navbar.mySecurity",
    "svb.navbar.myAlerts": "svb.navbar.myAlerts",
    "svb.navbar.hiUser": "svb.navbar.hiUser",
    "svb.navbar.help": "svb.navbar.help",
    "svb.navbar.mainMenu": "svb.navbar.mainMenu",
    "svb.navbar.lastLogin": "svb.navbar.lastLogin",
    "svb.navbar.pacificTime": "svb.navbar.pacificTime",
    "svb.navbar.onlineBanking": "svb.navbar.onlineBanking",
    "svb.navbar.moreContacts": "svb.navbar.moreContacts",
    "svb.navbar.unitedStates": "svb.navbar.unitedStates",
    "svb.navbar.unitedKingdom": "svb.navbar.unitedKingdom",
    "svb.navbar.logout": "svb.navbar.logout"
};
