"use strict";

var _ = require('lodash');

module.exports = [
    "$scope",
    "$uibModalInstance",
    "$interval",
    "$q",
    "options",
    function ($scope,
              $uibModalInstance,
              $interval,
              $q,
              options) {

        options = _.merge({
            timeout: 2000 // 2 seconds by default
        }, options);

        var promises = [
            $interval(function () {
            }, options.timeout, 1)
        ];

        if (options.work) {
            promises.push(options.work());
        }

        $q.all(promises).then(function () {
            $uibModalInstance.close();
        });
    }
];
