"use strict";

var translate = require("angular-translate");
var sanitize = require("angular-sanitize");
var services = require("../services/servicesModule");
var filters = require("../filters/filtersModule");
var ooba = require("../ooba/oobaModule");

var moduleDependencies = [
    translate,
    sanitize,
    services,
    filters,
    ooba
];
const navApp = angular.module("svb.navbar",moduleDependencies);

navApp.config(require("./locales/locale"))
    .directive("svbNavbar", require("./navbarDirective"))
    .directive("svbProfile", require("./profile/profileDirective"))
    .directive("svbContactUs", require("./contactUs/contactUsDirective"))
    .directive("svbHoverMenuButton", require("./hoverMenuButton/hoverMenuButton"))
    .directive("svbLink", require("./hoverMenuButton/linkDirective"))
    .directive("svbMobileMenu", require("./mobileMenu/mobileMenuDirective"));

navApp.factory("timerInterceptor", ($q, $log, $rootScope) => {
  return {
    response: (response) => {
      return response;
    },

    request: function (config) {
      //  console.log($rootScope);
      if ($rootScope.headerData) {
		$rootScope.oldTime = new Date();
      }
      return config;
    },
    requestError: function (rejection) {
      // Contains the data about the error on the request and return the promise rejection.
      return $q.reject(rejection);
    }
  };
});
navApp.config([
  "$httpProvider",
  function ($httpProvider) {
    $httpProvider.interceptors.push("timerInterceptor");
  }
]);

module.exports = "svb.navbar";