"use strict";

var _ = require("lodash");

/**
 *
 * Service that returns a list of error messages for a fieldId where
 * each error message is define by key(string) and message(string). Expects
 * all error messages to be define in config file under the 'errorMessages' object -
 * which is grabbed from configService
 *
 * Example in config file...
 *
 * "errorMessages": {
 * 		'input1': [
 * 			{
 * 				'key': 'required',
 * 				'message': 'payments.validation.required'
 * 			}
 * 		],
 * 		'input2': [
 * 			{
 * 				'key': 'required',
 * 				'message': 'payments.validation.required'
 * 			},
 * 			{
 * 				'key': 'minlength',
 * 				'message': 'payments.validation.minlength'
 * 			}
 * }
 *
 * @param errorMessages - errorMessage object from configService
 * @constructor
 */
function ErrorMessageService(errorMessages) { // NOSONAR
    this.errorMessages = errorMessages;
}

ErrorMessageService.prototype = {

    /**
     * Returns the given error messages for a specific fieldId
     */
    getErrMsgs: function getErrMsgs(fieldId) {
        var errMsgs = this.errorMessages[fieldId];

        if (!errMsgs) {
            throw new Error("No error message found for requested fieldId: " + fieldId);
        }

        return errMsgs;
    },

    setValue: function setValue(fieldId, errorId, errorValue) {
        let errMsg = this.errorMessages[fieldId].find((error) => error.key === errorId);

        if (!errMsg) {
            throw new Error(`No error message found for requested fieldId: ${fieldId} - errorId: ${errorId}`);
        }

        errMsg.value = errorValue;
    },

    /**
     * Ensure that errorMessages are set, if not it will throw
     */
    validate: function validate() {
        if (!this.errorMessages) {
            throw new Error("Config validation error: config does not specify errorMessages");
        }

        // Validate every fieldId has a key and message
        _.each(this.errorMessages, function (fieldIdObj, fieldId) {
            _.each(fieldIdObj, function (error, idx) {
                if (!error.key) {
                    throw new Error("Config validation error: " + fieldId + "[" + idx + "]" + " is missing key");
                }

                if (!error.message) {
                    throw new Error("Config validation error: " + fieldId + "[" + idx + "]" + " is missing message");
                }
            });
        });
    }
};

module.exports = ["configService", function (configService) {
    var config = configService.getConfig();

    var errorMessageService = new ErrorMessageService(config.errorMessages);

    // Validate the config now. Will cause entire app to fail to start if
    //errorMessages do not exist in the configs
    errorMessageService.validate();
    return errorMessageService;
}];