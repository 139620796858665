class MatchMediaService {
    constructor() {
        this._listeners = {};
        this._matchMediaListeners = {};
        this._onMediaQueryMatch = this._onMediaQueryMatch.bind(this);
    }

    /**
     * @returns ResponsiveLabelService
     */
    $get() {
        return this;
    }

    /**
     * @param {string} mediaQuery
     * @param {Function} onMatch
     */
    subscribeMediaMatch(mediaQuery, onMatch) {
        const mediaQueryListener = window.matchMedia(mediaQuery);

        if (!Array.isArray(this._listeners[mediaQuery])) {
            mediaQueryListener.addListener(this._onMediaQueryMatch);
            this._listeners[mediaQuery] = [];
        }

        this._listeners[mediaQuery].push(onMatch);
        this._onMediaQueryMatch(mediaQueryListener);
    }

    /**
     * @private
     */
    _onMediaQueryMatch(mediaQueryListener) {
        if (mediaQueryListener.matches && this._listeners[mediaQueryListener.media]) {
            this._listeners[mediaQueryListener.media].forEach((listener) => {
                listener(mediaQueryListener);
            });
        }
    }
}

MatchMediaService._instance = null;
MatchMediaService.$inject = [];

/**
 * Keep only single instance
 * @static
 */
MatchMediaService.singelton = () => {
    if (MatchMediaService._instance) {
        return MatchMediaService._instance;
    }

    return MatchMediaService._instance = new MatchMediaService;
};

module.exports = () => MatchMediaService.singelton();