let currentTabsIndex = 0;

class TabsComponentController {
    $onInit() {
        currentTabsIndex += 1;
        this.panels = [];
        this.activePanelIndex = 0;
    }

    /**
     * Returns unique id of current tabs widget
     * @returns string
     */
    getId() {
        return `tabs-${currentTabsIndex}`;
    }

    /**
     * Sets active panel
     * @param {TabPanelComponentController} panel
     * @returns void
     */
    setActive(panel) {
        const newPanelIndex = this.panels.indexOf(panel);

        // Exit if it's same tab
        if (this.activePanelIndex === newPanelIndex) {
            return;
        }

        this.activePanelIndex = newPanelIndex;

        if (this.onTabChange) {
            this.onTabChange({ $panel: panel });
        }
    }

    /**
     * Registers panel
     * @param {TabPanelComponentController} panel
     * @returns void
     */
    addPanel(panel) {
        this.panels.push(panel);

        if (this.onAddPanel) {
            this.onAddPanel({ $panel: panel });
        }
    }
}

module.exports = {
    transclude: true,
    templateUrl: 'core/modules/widgets/tabs/tabsComponent.html',
    controller: TabsComponentController,
    bindings: {
        onTabChange: '&?onTabChange',
        onAddPanel: '&?onAddPanel'
    }
};