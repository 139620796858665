// TODO: Merge SASS and JSON variables somehow?
const breakpoints = require('./responsiveBreakpoints.json');
const xsBreakpointQuery = `(max-width: ${breakpoints.xs - 1}px)`;
const smBreakpointQuery = `(max-width: ${breakpoints.sm - 1}px) and (min-width: ${breakpoints.xs}px)`;
const mdBreakpointQuery = `(max-width: ${breakpoints.lg - 1}px) and (min-width: ${breakpoints.sm}px)`;
const lgBreakpointQuery = `(min-width: ${breakpoints.lg}px)`;

class ResponsiveLabelDirective {
    constructor() {
        this.restrict = 'A';
        this.scope = {
            svbResponsiveLabel: '@'
        };
        this.template = `
            <span
                translate="{{ translationCode  }}"
                translate-default="{{ fallbackCode | translate }}"
                translate-keep-content="false"
            ></span>
        `;
        this.controller = this.controller.bind(this);
        this.controller.$inject = [
            '$scope',
            '$timeout',
            'matchMediaService'
        ];
    }

    controller($scope, $timeout, matchMediaService) {
        $scope.fallbackCode = $scope.svbResponsiveLabel.toString();
        $scope.translationCode = $scope.svbResponsiveLabel;

        $scope.onBreakpointMatch = () => {
            $timeout(() => {
                $scope.$digest();
            });
        };

        $scope.onXsBreakpoint = () => {
            $scope.translationCode = `${$scope.fallbackCode}@xs`;
            $scope.onBreakpointMatch();
        };

        $scope.onSmBreakpoint = () => {
            $scope.translationCode = `${$scope.fallbackCode}@sm`;
            $scope.onBreakpointMatch();
        };

        $scope.onMdBreakpoint = () => {
            $scope.translationCode = `${$scope.fallbackCode}@md`;
            $scope.onBreakpointMatch();
        };

        $scope.onLgBreakpoint = () => {
            $scope.translationCode = `${$scope.fallbackCode}@lg`;
            $scope.onBreakpointMatch();
        };

        matchMediaService.subscribeMediaMatch(xsBreakpointQuery, $scope.onXsBreakpoint);
        matchMediaService.subscribeMediaMatch(smBreakpointQuery, $scope.onSmBreakpoint);
        matchMediaService.subscribeMediaMatch(mdBreakpointQuery, $scope.onMdBreakpoint);
        matchMediaService.subscribeMediaMatch(lgBreakpointQuery, $scope.onLgBreakpoint);
    }
}

module.exports = () => new ResponsiveLabelDirective;