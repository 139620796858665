"use strict";

module.exports = function (name, regex) {
    return function () {
        return {
            require: "ngModel",
            restrict: "A",
            link: function (scope, elem, attr, ngModel) {
                function validator(value) {
                    return regex.test(value);
                }
                ngModel.$validators[name] = validator;
            }
        };
    };
};