"use strict";

var moduleDependencies = [
    "svb.services",
    require("./sortCodeFilter/sortCode")
];

angular.module("svb.filters", moduleDependencies)
    .filter("prependEconnectUrl", require("./prependEconnectUrl/prependEconnectUrl"))
    .filter("decodeHTML", require("./decode/decode"));

module.exports = "svb.filters";