"use strict";

var moduleDependencies = [
    'pascalprecht.translate',
    'svb.services'
];

angular.module("svb.errorMessage", moduleDependencies)
    .component("svbErrorMessage", require("./errorMessageComponent"));

module.exports = "svb.errorMessage";