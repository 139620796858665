"use strict";

// Directive used to display a contact us hover button
module.exports = ["configService", function (configService) {
    return {
        templateUrl: "core/modules/navbar/contactUs/contactUs.html",
        link: function (scope) {
            var contactUsInfo = configService.getConfig().staticContactUsInfo;
            scope.contactInfo = contactUsInfo.contactInfo;
            scope.extraInfo = contactUsInfo.extraInfo;

            var toggleInfo = function () {
                if (scope.isCreditCardUser) {
                    scope.ccContactInfo = scope.isBusinessEliteCardUser ?
                        contactUsInfo.businessEliteCard : contactUsInfo.businessAndMultiCard;
                    scope.ccUKContactInfo = contactUsInfo.ukCard;
                }
            };

            scope.$on('navScopeUpdate', toggleInfo);
            toggleInfo();
        }
    };
}];