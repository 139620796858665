"use strict";

const AUTH_REQUIRED = 1;
const AUTH_TEXT_CALL = 2;
const CONTACT_TYPE_CALL = "call";
const CONTACT_TYPE_TEXT = "text";

function authenticationModalController($scope, $uibModal, $uibModalInstance, $filter, $rootScope, // NOSONAR
                                       $timeout, EVENTS, options, oobaModalService,    // NOSONAR
                                       authenticationDataService, ctvConstants, // NOSONAR
                                       $interval, $document, $window, configService, $q) {     // NOSONAR
    $scope.authStep = AUTH_REQUIRED;
    $scope.devices = options.data? options.data.devices: {};
	$scope.isPushEnabled = options.data? options.data.isPushEnabled : false;
    $scope.enableAssist = options.enableAssist;
    $scope.pollingCancelled = false;
    $scope.secureURLId = options.secureURLId || 3; // 3 means not a navigation link
    $scope.contactText = options.contactText;
    $scope.ctvDefer = options.defer || $q.defer();
    $scope.referenceObjectHash = options.referenceObjectHash;
    $scope.oobaId = options.oobaId;
    $scope.verificationType = null;

    $scope.$on('$destroy', () => {
        $scope.cancelTimer();
    });

    function hideLoader(hide) {
        $scope.processing = !hide;
        $scope.authenticating = !hide;
    }

    hideLoader(true);

    const config = configService.getConfig();
    const interval = config.authenticatePollingInterval;

    $scope.closeAndShowError = () => {
        $uibModalInstance.result.then(
            () => { },
            () => {
                return $timeout(300).then(() => {
                    return oobaModalService.showAuthenticationErrorModal();
                });
            }
        );

        $uibModalInstance.dismiss();
    };

    $scope.showAlertSystemError = () => {
        let newScope = $rootScope.$new();
        newScope.errModal = {
            body: $filter("translate")('svb.authentication.pleaseRetry'),
            'title': $filter("translate")('svb.authentication.systemError')
        };

        $uibModal.open({
            animation: true,
            windowClass: 'mur-modal error-modal',
            templateUrl: "core/modules/ooba/errorModal.html",
            scope: newScope
        });
    };

    $scope.closeAndShowLogoutError = () => {
        $uibModalInstance.result.catch(() => {
            return $timeout(300).then(() => {
                return oobaModalService.showAuthenticationLogoutErrorModal().result.finally(() => {
                    $scope.ctvDefer.reject({
                        'message': 'Maximum attempts exceeded...'
                    });
                });
            });
        });

        $uibModalInstance.dismiss();
    };

    // Called when exiting the modal via the close button or cancel link
    $scope.cancel = () => {
        if (window.TLT) {
            window.TLT.flushAll();
        }
        if ($scope.verificationType === CONTACT_TYPE_CALL) {
            cancelAuthentication();
        } else {
            cancelCTVFlow();
        }
    };

    $scope.logout = () => {
        $window.location.href = config.eConnectLogoutUrl;
    };

    $scope.cancelTimer = () => {
        if ($scope.intervalInstance) {
            $interval.cancel($scope.intervalInstance);
            $scope.intervalInstance = null;
        }
    };

    //This flag is used to indentify which browser is being used by end user.
    $scope.isSafaribrowser = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
    // Called when selecting text message verification
    $scope.onClickText = (device) => {
        initiateCTVProcess(device, CONTACT_TYPE_TEXT, $filter, $timeout, interval);
    };

    // Called when selecting phone call verification
    $scope.onClickCall = (device) => {
        initiateCTVProcess(device, CONTACT_TYPE_CALL, $filter, $timeout, interval);
    };

	$scope.onClickPush = () => {
		initiatePushNotificationProcess();
	};

    // Called when selecting to have a bank administrator activate updates
    $scope.notifyBankAdministrator = () => {
        // TO-DO: Make service call here
        $uibModalInstance.dismiss();
    };

    $scope.authenticate = (code) => {
        performTextAuthenticate(code, $uibModalInstance);
    };

    $scope.tooltipVisible = false;

    // Show tooltip when link clicked
    $scope.showTooltip = (event) => {
        $scope.tooltipVisible = true;
        event.stopPropagation();
    };

    $scope.hideTooltip = () => {
        $scope.$apply(() => {
            $scope.tooltipVisible = false;
        });
    };

    $document.bind('click', () => {
        $scope.hideTooltip();
    });

    $scope.$on('$destroy', () => {
        $document.off('click', $scope.hideTooltip);
    });

    function startAutoCloseTimer() {
        $scope.intervalInstance = $interval(() => {
            $scope.cancel();
        }, config.timeouts.authCodeExpirationTime, 1);
    }


    function tltLogDomCapture(tltScreenViewName) {
        if (window.TLT) {
            window.TLT.logScreenviewLoad(tltScreenViewName);
            window.TLT.logDOMCapture();
            window.TLT.flushAll();
        }
    }

    function performCTVPoll($scope, $timeout, interval) {
        if (window.TLT) {
            window.TLT.flushAll();
        }
        authenticationDataService.pollCTV($scope.oobaId, $scope.secureURLId, $scope.referenceObjectHash).then(
            (data) => {
                switch (data.data.status) {
                    case "POLL":
                        // Delay for polling interval and make service call again
                        $timeout(interval).then(() => {
                            tltLogDomCapture("callme");
                            if ($scope.pollingCancelled) {
                                // If user closed modal, stop polling - but still notify client that it was closed
                                $scope.ctvDefer.reject({
                                    message: 'User closed light box'
                                });
                            }
                            else {
                                return performCTVPoll($scope, $timeout, interval);
                            }
                        });
                        break;
                    case "SUCCESS":
                        $scope.pollingCancelled = true;

                        // Successfully authenticated
                        $uibModalInstance.result.then(() => {
                            const successModal = oobaModalService.showAuthenticationSuccessModal(options);
                            successModal.result.then(() => {
                                $scope.ctvDefer.resolve({
                                    oobaId: $scope.oobaId
                                });
                            });
                        });

                        $uibModalInstance.close();
                        break;
                    default:
                        handleErrors(data.messages);
                        break;
                }
            },
            (status) => {
                handleErrors(status.messages);
            }
        );
    }

    function handleErrors(errors) {
        if (errors && errors.length > 0) {
            let message = errors[0];
            hideLoader(true);

            if (message.code === ctvConstants.authCode.logout) {
                $scope.closeAndShowLogoutError(message.message);
            } else {
                showErrorAndReject();
            }
        }
    }

    function initiateCTVProcess(device, type, $filter, $timeout, interval) {
        startAutoCloseTimer();

        $scope.processing = true;
        $scope.selectedDevice = device;
        $scope.selectedDevice.authCode = "";

        const headerKey = type === CONTACT_TYPE_TEXT ? "svb.authentication.sentTextMessage"
            : "svb.authentication.weAreCalling";
        $scope.verifyHeader = $filter("translate")(headerKey, {
            name: "<b>" + device.phoneName + "</b>",
            number: device.phoneNumber
        });

        $scope.phoneNumber = device.phoneNumber;

        const oobaId = $scope.oobaId;
        const deviceId = device.phoneId.toString();
        let cmd;

        // Only use cmd parameter for text verification
        $scope.verificationType = type;
        if (type === CONTACT_TYPE_TEXT) {
            cmd = 6;
            $scope.texting = true;
        } else {
            cmd = 0;
            $scope.calling = true;
        }
        authenticationDataService.initiateCTV(oobaId, deviceId, cmd, {
            secureActionUrlId: $scope.secureURLId
        }).then(
            (res) => {
                $scope.processing = false;
                $scope.authStep = AUTH_TEXT_CALL;
                $scope.contactType = type;

                if (type === CONTACT_TYPE_CALL) {
                    $scope.callAuthCode = res.data.data.securityCode;
                    $scope.calling = false;
                }
                else {
                    $scope.texting = false;
                    $scope.callAuthCode = null;
                }

                if (type === CONTACT_TYPE_CALL) {
                    performCTVPoll($scope, $timeout, interval);
                }
            },
            () => {
                showErrorAndReject();
            }
        );
    }

	function initiatePushNotificationProcess() {
		startAutoCloseTimer();

		let actionId = 20;
		$scope.selectedDevice = {};
		$scope.processing = true;

		$scope.verifyHeader = $filter("translate")('svb.authentication.sentPushMessage');

        $scope.verificationType = 'push';

        $scope.authCode = "";

		authenticationDataService.initiatePush($scope.oobaId, 0, actionId, {
			secureActionUrlId: $scope.secureURLId
		}).then(
			() => {
				$scope.processing = false;
				$scope.authStep = AUTH_TEXT_CALL;
				$scope.contactType = 'push';
				$scope.callAuthCode = null;
				$scope.calling = false;
			},
			() => {
				showErrorAndReject();
			}
		);
	}

    function performTextAuthenticate(code, modalInstance) {
        if ($scope.authForm.$invalid) {
            return;
        }

        if (window.TLT) {
            window.TLT.flushAll();
        }
        hideLoader(false);

        return authenticationDataService
            .verifyTextCode(code, $scope.oobaId, $scope.secureURLId, $scope.referenceObjectHash)
            .then(() => {
                modalInstance.close();
                modalInstance.result.then(() => {
                    let successModalInstance = oobaModalService.showAuthenticationSuccessModal(options);
                    successModalInstance.result.then(() => {
                        $scope.ctvDefer.resolve({
                            oobaId: $scope.oobaId
                        });
                    });
                });
            }).catch((status) => {
                let errors = status.messages;
                if (errors && errors.length > 0) {
                    // 13106/7 indicate a bad code, but that the user has not reached their max attempts
                    if (errors[0].code === ctvConstants.authCode.badCode.bc13107 ||
                        errors[0].code === ctvConstants.authCode.badCode.bc13106) {
                        // On unsuccessful response, hide mask and display error messages
                        hideLoader(true);
                        $scope.authErrors = errors;
                        $scope.selectedDevice.authCode = "";
                    } else if (errors[0].code === ctvConstants.authCode.logout) {
                        $scope.closeAndShowLogoutError(status);
                    } else if (errors[0].code === ctvConstants.authCode.syserror) {
                        showSysErrorAndReject();
                    } else {
                        showErrorAndReject();
                    }
                } else {
                    // If there are no error messages, just hide the processing mask
                    hideLoader(true);
                }
            });
    }

    function showSysErrorAndReject() {
        $uibModalInstance.close();
        $scope.ctvDefer.reject({
            'message': 'CTV flow failed. System error.'
        });
        $scope.showAlertSystemError();
        hideLoader(true);
    }

    function showErrorAndReject() {
        // For other error code, close the modal and show error modal
        $scope.closeAndShowError();
        cancelCTVFlow();
    }

    function cancelCTVFlow() {
        // reject ctv flow
        $scope.cancelTimer();
        $uibModalInstance.dismiss();
        $scope.ctvDefer.reject({
            'message': 'CTV flow failed. System error.'
        });

        $scope.pollingCancelled = true;
        hideLoader(true);
    }

    function cancelAuthentication() {
        authenticationDataService.cancelAuthentication($scope.oobaId, $scope.secureURLId).then(
            () => {
                // Authentication successfully cancelled on the backend. Do nothing.
                cancelCTVFlow();
            },
            (data) => {
                let message = data.messages && data.messages.length > 0 ? data.messages[0] : null;
                if (message) {
                    if (message.code === ctvConstants.authCode.logout) {
                        $scope.closeAndShowLogoutError(data.messages);
                    } else if (message.code === ctvConstants.authCode.syserror) {
                        showSysErrorAndReject();
                    } else {
                        showErrorAndReject();
                    }
                } else {
                    showErrorAndReject();
                }
            }
        );
    }
}

module.exports = [
    "$scope", "$uibModal", "$uibModalInstance", "$filter", "$rootScope",
    "$timeout", "EVENTS", "options", "oobaModalService", "authenticationDataService", "ctvConstants",
    "$interval", "$document", "$window", "configService", "$q",
    authenticationModalController
];
