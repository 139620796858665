// This component is in charge of adding search functionality to a table.
class TypeaheadComponent {
	onOpen(isOpen) {
		this.isDropDownSelected = isOpen;
	}
}

export default angular
	.module('svb-typeahead-component', [
		require("angular-translate")
	])
	.component('svbTypeahead', {
		restrict: "E",
		templateUrl: "core/modules/widgets/typeahead/typeahead.html",
		replace: true,
		transclude: {
			selectMatch: "?selectMatch",
			selectChoices: "?selectChoices"
		},
		bindings: {
			idName: "<",
			placeholderKey: "<",
			itemList: "=",
			orderBy: "<"
		},
		controller: TypeaheadComponent
	})
	.name;
