'use strict';

/**
 * Open a link in a new browser window
 * @param url address to go to
 * @param name title of window
 * @param options object representing options for window
 */
var openNewWindow = function (url, name, options) {
    var stringOpts = "scrollbars=yes,resizable=yes,status=yes,toolbar=no";

    // Iterate over the options and convert them to a single string
    if (options) {
        Object.keys(options).forEach(function (key) {
            stringOpts += "," + key + "=" + options[key];
        });
    }
    window.open(url, name, stringOpts);
};

/**
 * Wrapper for window.location.replace. Should be used whenever a redirect is required
 * so that we have a stubbable method for verifying redirects when testing.
 * window.location.replace is not writable, so it can't be stubbed directly.
 * @param url
 */
var locationReplace = function (url) {
    window.location.replace(url);
};

module.exports = {
    openNewWindow: openNewWindow,
    locationReplace: locationReplace
};