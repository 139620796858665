"use strict";

var moduleDependencies = [
    'pascalprecht.translate'
];

angular.module("svb.errorHandler", moduleDependencies)
    .config(require("./locales/locale"))
    .directive("svbErrorHandler", require("./errorHandlerDirective"));

module.exports = "svb.errorHandler";