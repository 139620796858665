import _ from 'lodash';
let inView = require('in-view');

inView.offset({
    top: 300,
    right: 0,
    bottom: 150,
    left: 0
});

// Controls the rendering as well as provide hooks to customize
class approveRejectTableCtrl {
    constructor($scope) {
        'ngInject';
        this.$scope = $scope;
    }

    $onInit() {
		this.data = this.data || [];
		this.context = this.context || this.options.context || {};
		this.inViewFn = this.inViewFn.bind(this);

        this.data.forEach((row) => {
            row.approveReject = false;
            row.approve = false;
            row.reject = false;
        });

        this.context._approveRejectTable = {
            selectAllApprove: this.selectAllApprove.bind(this),
            selectAllReject: this.selectAllReject.bind(this),
            selectAll: this.selectAll.bind(this),
            isAllApproved: this.isAllApproved.bind(this),
            isAllRejected: this.isAllRejected.bind(this),
            isAllSelected: this.isAllSelected.bind(this),
            toggleExpandSection: this.toggleExpandSection.bind(this)
        };

        _.assignInWith(this.options.columns, {
                approveRejectHeader: {
                    tabindex: -1,
                    class: 'hidden-xs col-sm-3 text-center',
                    headerTemplateUrl: 'core/modules/widgets/approveRejectTable/header/approveRejectHeader.html',
                    subHeaderTemplateUrl: 'core/modules/widgets/approveRejectTable/cell/approveRejectAll.html',
                    cellTemplateUrl: 'core/modules/widgets/approveRejectTable/cell/approveReject.html'
                },
                approveRejectHeaderMobile: {
                    tabindex: -1,
                    class: 'col-xs-2 hidden-sm hidden-md hidden-lg text-center pull-right no-gutter-left no-gutter-right approve-header-mobile',
                    label: "",
                    subHeaderTemplateUrl: 'core/modules/widgets/approveRejectTable/cell/approveRejectAll.mobile.html',
                    cellTemplateUrl: 'core/modules/widgets/approveRejectTable/cell/approveReject.mobile.html'
                }
            }
        );
    }

    $postLink() {
        inView("approve-reject-table")
            .on('enter', this.inViewFn)
            .on('exit', this.inViewFn);
    }

    inViewFn() {
        this.inView = !this.inView;
        // The callback exists outside of the angular world
        this.$scope.$digest();
    }

    hasSelection() {
        return _.some(this.data, (row) => {
            return row.approve === true || row.reject === true;
        });
    }

    hasSelectionMobile() {
        return this.data.some((row) => row.approveReject === true);
    }

    selectAllApprove(state) {
        this.data.forEach((row) => {
            row.approve = state;
            row.reject = false;
        });
    }

    selectAllReject(state) {
        this.data.forEach((row) => {
            row.approve = false;
            row.reject = state;
        });
    }

    selectAll(state) {
        this.data.forEach((row) => {
            row.approveReject = state;
        });

    }

    isAllApproved() {
        return !this.data.some((row) => {
            return row.approve !== true;
        });
    }

    isAllRejected() {
        return !this.data.some((row) => {
            return row.reject !== true;
        });
    }

    isAllSelected() {
        return !this.data.some((row) => {
            return row.approveReject !== true;
        });
    }

    toggleExpandSection(shouldOpen) {
        this.currentPage = shouldOpen ? 1 : 0;
    }
}

export default angular
    .module('approval-reject-table', [
        require("angular-translate"),
        require('../../services/servicesModule')
    ])
    .config(require("./locales/locale"))
    .component('approveRejectTable', {
        restrict: 'E',
		transclude: {
			extraAction: '?extraAction'
		},
        bindings: {
            data: '=',
            options: '=',
            sortOrder: '<',
            noResultKey: "<",
            rowsPerPage: "<?"
        },
        templateUrl: 'core/modules/widgets/approveRejectTable/approveRejectTable.html',
        controller: approveRejectTableCtrl,
        controllerAs: 'tableCtrl',
        bindToController: true
    })
    .name;