// Directive acts similar to ng-bind-html, except that it allows for html markup to contain
// angular directives as well
module.exports = ["$compile", function ($compile) {
    return function (scope, element, attrs) {
        scope.$watch(function (scope) {
            // watch the 'compile' expression for changes
            return scope.$eval(attrs.svbCompile);
        }, function (value) {
            // when the 'compile' expression changes assign it into the current DOM
            element.html(value);

            // compile the new DOM and link it to the current scope.
            $compile(element.contents())(scope);
        });
    };
}];
