"use strict";

module.exports = ["configService", function (configService) {
    return function (url) {
        var eConnectUrl = configService.getConfig().eConnectUrl;

        if (url && url.toLowerCase().indexOf("http") === 0) {
            return url;
        } else {
            if (!eConnectUrl) {
                throw "prependEconnectUrl: Filter requires eConnectUrl to exist";
            }
            return eConnectUrl + (url || "");
        }
    };
}];