	"use strict";

var moduleName = "svb-csrf-token-service";

function csrfService($q, $log, CSRF) {
	var csrfToken;

	return {
		getToken: getToken,
		setToken: setToken,
		refreshToken: refreshToken
	};

	function getToken() {
		if (!csrfToken) {
			return CSRF.save().$promise.then(function (response) {
				if (!(response && response.token)) {
					$log.error("Token fetch failed to provide a token");
					return $q.reject("Token fetch failed to provide a token");
				}
				csrfToken = response.token;
				return csrfToken;
			}).catch(function (e) {
				$log.error("token fetch failed");
				return $q.reject(e);
			});
		} else {
			return $q.resolve(csrfToken);
		}
	}

	function setToken(token) {
		csrfToken = token;
	}

	function refreshToken() {
		csrfToken = '';
		return getToken();
	}
}

angular.module(moduleName, [
	require('./csrfResource')
])
		.factory("csrfService", ["$q", "$log", "CSRF", csrfService]);
module.exports = moduleName;

