"use strict";
/**
 * Custom select wrapper around the ui.select node-module.  Requires selectId, label, lookupKey values in order to build
 * a ui.select dropdown using a select2 theme.
 *
 *        - ng-model: the model value to bind the selection to
 *        - items: the list of items to populate the dropdown with
 *
 */

module.exports = function svbSelect(directiveValidationService) {
    return {
        restrict: "E",
        require: "ngModel",
        scope: {
            items: "=",
            selectId: "@",
            translationStr: "@label",
            placeholder: "@placeHolder",
            name: "@",
            ngDisabled: "=",
            model: "=ngModel",
            noValidate: "="
        },
        transclude: {
            'helpBlock': '?helpBlock'
        },
        templateUrl: "core/modules/formElements/select/selectDirective.html",
        link: function (scope, element, attrs, ngModel) {
            directiveValidationService.enforceRequiredAttributes("SvbSelect", attrs, "selectId", "label",
                "items", "placeHolder");

            scope.$watchCollection("items", function (items) {
                //Validate required field in items array objects
                if (items && items.length) {
                    for (var i = 0; i < scope.items.length; i++) {
                        if (!items[i].displayValue) {
                            throw new Error("Svb select requires items array objects to contain displayValue property");
                        }
                    }

                    //If we only have 1 value in the select, default to it.
                    if (items.length === 1) {
                        ngModel.$setViewValue(items[0]);
                    }
                }
            });

            scope.onSelect = function () {
                ngModel.$setDirty();
            };

            scope.onOpen = function (isOpen) {
                if (!isOpen) {
                    ngModel.$setTouched();
                }
            };

            scope.getId = function () {
                return scope.selectId ? scope.selectId + "-id" : '';
            };

            scope.shouldShowErrors = function () {
                return (!scope.noValidate) && ngModel.$touched;
            };

            scope.errorObj = ngModel.$error;
        }
    };
};
