"use strict";

var angular = require("angular");
require('babel-polyfill');
var filters = require("./modules/filters/filtersModule");
var footer = require("./modules/footer/footerModule");
var interceptor = require("./modules/interceptor/interceptorModule");
var navbar = require("./modules/navbar/navbarModule");
var services = require("./modules/services/servicesModule");
var validators = require("./modules/validation/validationModule");
var formElements = require("./modules/formElements/formElementsModule");
var errorMessage = require("./modules/errorMessage/errorMessageModule");
var widgets = require("./modules/widgets/widgetsModule");
var uiselect = require("ui-select");
var viewport = require("./modules/viewport/index");
var ooba = require("./modules/ooba/oobaModule");
var spinner = require("./modules/spinner/spinnerModule");
var errorModule = require("./modules/errorHandler/errorHandlerModule");
var sanitize = require("./modules/sanitize/sanitizeModule");
var interop = require("./modules/interop/interopModule");

var animate = require("angular-animate");
var translate = require("angular-translate");
var uiRouter = require('angular-ui-router');
var messages = require("angular-messages");
var angularAria = require("angular-aria");
var bootstrap = require("angular-ui-bootstrap");
var lazyLoad = require("oclazyload");
var angularMoment = require("angular-moment");
var ngInfiniteScroll = require('ng-infinite-scroll');
require("angular-promise-extras");

window.jQuery = window.$ = require("jquery"); // required for Credit Cards app integration

// Viewport for the responsive UI
window.Viewport = viewport;
viewport.createAngularModule(angular);

var moduleDependencies = [
    filters,
    footer,
    interceptor,
    navbar,
    ooba,
    services,
    validators,
    uiselect,
    formElements,
    errorMessage,
    spinner,
    errorModule,
    messages,
    widgets,
    sanitize,
    interop,
    translate,
    bootstrap,
    uiRouter,
    animate,
    angularAria,
    lazyLoad,
    angularMoment,
    ngInfiniteScroll,
    'ngPromiseExtras'
];

var APP_NAME = 'appSVB';

angular
    .module(APP_NAME, moduleDependencies)
    .config(["$httpProvider", function ($httpProvider) {
        $httpProvider.interceptors.push("errorInterceptor");
        $httpProvider.interceptors.push("csrfInterceptor");
        $httpProvider.interceptors.push("secondaryAuthInterceptor");
    }])
    .constant("EVENTS", require("./constants/events"))
    .constant("PRODUCT_TYPES", require("./constants/productTypes"))
    .constant("DAYS", require("./constants/days"))
    .run(["promiseService", "svb.interop.requireJSLoader", function (promiseService, requireJSLoader) {
        promiseService.register(requireJSLoader.load(), 'fullPage');
    }]).run(["mobileCheckService", "$rootScope", function (mobileCheckService, $rootScope) {
        $rootScope.isMobile = mobileCheckService.isMobile();
}]).run(['$http', function ($http) {
	$http.defaults.headers.common['Accept'] = 'application/json';
}]);

require("../../app/target/docroot/assets/js/templates");
require("./stateManager");

module.exports = APP_NAME;