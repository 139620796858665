"use strict";

// Module that determines what to show when the
// page loads (spinner, error page or body).
module.exports = [function () {
	return {
		transclude: {
			spinnerSlot: "spinnerContent",
			bodySlot: "bodyContent",
			errorSlot: "errorContent"
		},
		scope: {
			sectionName: "@"
		},
		templateUrl: "core/modules/spinner/loadingHandler/loadingHandlerView.html",
		link: function (scope) {
			scope.hideSpinner = false;
			scope.hideError = true;
			scope.hideBody = true;

			scope.$on("PromiseQueue-Successful", function (event, name) {
				if (scope.sectionName === name) {
					scope.hideSpinner = true;
					scope.hideError = true;
					scope.hideBody = false;

					hideInitialSpinner(scope.sectionName);
				}
			});

			scope.$on("PromiseQueue-Failed", function (event, name) {
				if (scope.sectionName === name) {
					scope.hideSpinner = true;
					scope.hideError = false;
					scope.hideBody = true;

					hideInitialSpinner(scope.sectionName);
				}
			});

			// Hide the bodySection when transition between states
			scope.$on("$stateChangeStart", () => {
				let pageBody = document.querySelector(".page-body-loader");

				if (scope.sectionName === 'bodySection') {
					scope.hideSpinner = false;
					scope.hideError = true;
					scope.hideBody = true;
				}

				if (pageBody) {
					pageBody.classList.remove("eC-loaded");
				}
			});

			// This spinner is here to create a seamless transition between eConnect and Dashboard
			// As a result it must appear in index.html and cannot be added as part of Angular because this takes
			// a while to start before it can paint anything.
			// As a result we have to use plain Javascript to hide the spinner.
			function hideInitialSpinner(sectionName) {
				if (sectionName === "fullPage") {
					document.querySelector("#mur-loading").style.display = "none";
					document.querySelector("#mur-welcome").style.display = "none";
				}
			}
		}
	};
}];