"use strict";

module.exports = ["$http", "urlService", function ($http, urlService) {
	return {
		get: function () {
			const userPreferencesUrl = urlService.getUrlForResource('userPreferences');

			return $http.get(userPreferencesUrl, {cache: true});
		}
	};
}];