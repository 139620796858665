"use strict";

function csrfTokenServiceFactory($http, urlService, $log) {
    function CsrfTokenService() { // NOSONAR (relax "Function names should comply with a naming convention")`
    }

    CsrfTokenService.prototype = {
        getTokenForSession: function () {
            return $http.post(urlService.getUrlForResource("csrfToken")).then(function (response) {
                $log.debug("fetched new token: " + JSON.stringify(response.data));
                return response.data.token;
            });
        }
    };

    return new CsrfTokenService();
}

module.exports = ["$http", "urlService", "$log", csrfTokenServiceFactory];