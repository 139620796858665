module.exports = {
    "svb.navbar.search": "Search",
    "svb.navbar.contactUs": "Contact Us",
    "svb.navbar.myProfile": "My Profile",
    "svb.navbar.mySecurity": "My Security",
    "svb.navbar.myAlerts": "My Alerts",
    "svb.navbar.hiUser": "Hi, {{name}}",
    "svb.navbar.help": "Help",
    "svb.navbar.mainMenu": "Main Menu",
    "svb.navbar.lastLogin": "Last Login: {{timestamp}}",
    "svb.navbar.pacificTime": "PT",
    "svb.navbar.onlineBanking": "Online Banking Services/Help Desk",
    "svb.navbar.moreContacts": "More SVB Contacts",
    "svb.navbar.unitedStates": "United States",
    "svb.navbar.unitedKingdom": "United Kingdom",
    "svb.navbar.logout": "Logout",
    "svb.navbar.creditCards": "CREDIT CARD SERVICES",
    "svb.navbar.homeTabName": "Home",
    "svb.navbar.ukTabName": "UK Services",
    "svb.navbar.fxTradingTabName": "FX Trading"
};
