"use strict";

var services = require("../services/servicesModule.js");

var moduleDependencies = [
    services,
    require("./csrfInterceptor/csrfInterceptor"),
    require("./csrfInterceptor/csrfInterceptorHelper"),
    require("./secondaryAuthInterceptor/secondaryAuthInterceptor")
];

angular.module("svb.interceptor", moduleDependencies)
    .factory("errorInterceptor", require("./errorInterceptor/errorInterceptorFactory"));

module.exports = "svb.interceptor";