"use strict";

module.exports = ["$translateProvider", function ($translateProvider) {
    // Our translations will go in here

    $translateProvider.translations('enUS', require("./enUS"));
    $translateProvider.translations('enUK', require("./enUK"));
    $translateProvider.translations('dev', require("./dev"));
    $translateProvider.preferredLanguage("enUS");
}];
