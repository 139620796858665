"use strict";

module.exports = ['$http', 'urlService', '$q', 'csrfTokenService',
    function ($http, urlService, $q, csrfTokenService) { 			// NOSONAR

        function getUrl (endpoint) {
            return urlService.getUrlForResource("ooba") + endpoint;
        }

        // Called for getting oobaId for backward compatibility
        function getOobaId () {
            return $http.post(getUrl('')).then((res) => {
                return res.data.data.oobaId;
            });
        }

        // Called to retrieve a user's device list in order to perform authentication
        function getCTVInfo (oobaId) {
            return $http.get(getUrl(oobaId + '/devices'));
        }

        // Called by selecting either the text or phone call option
        function initiateCTV (oobaId, deviceId, cmd, data) {
            let defer = $q.defer();
            csrfTokenService.getTokenForSession().then(
                (token) => {
                    data.csrfToken = token;

                    $http.post(getUrl(oobaId + '/devices/' + deviceId + '/actions/' + cmd), data, {}).then(
                        (res) => {
                            defer.resolve(res);
                        },
                        (res) => {
                            defer.reject(res);
                        }
                    );
                },
                (res) => {
                    defer.reject(res);
                }
            );

            return defer.promise;
        }

		// Called by selecting push notification option
		function initiatePush (oobaId, deviceId, cmd, data) {
			let defer = $q.defer();
			csrfTokenService.getTokenForSession().then(
				(token) => {
					data.csrfToken = token;

					$http.post(getUrl(oobaId + '/actions/' + cmd), data, {}).then(
						(res) => {
							defer.resolve(res);
						},
						(res) => {
							defer.reject(res);
						}
					);
				},
				(res) => {
					defer.reject(res);
				}
			);

			return defer.promise;
		}

        // Sends the text message code to the service to complete verification
        function verifyTextCode (code, oobaId, secureUrlId) {
            let options = {};
            const data = {
                textToVerifySecurityCode: code,
                secureActionUrlId: secureUrlId
            };


            let defer = $q.defer();
            csrfTokenService.getTokenForSession().then(
                (token) => {
                    data.csrfToken = token;

                    $http.post(getUrl(oobaId + '/actions/verify'), data, options).then(
                        (res) => {
                            // If response has a data parameter, user is successfully verified
                            if (res.data && res.data.data && res.data.data.status === "SUCCESS") {
                                defer.resolve({});
                            } else {
                                defer.reject(res.data);
                            }
                        },
                        (res) => {
                            defer.reject(res.data);
                        }
                    );
                },
                (res) => {
                    defer.reject(res.data);
                }
            );

            return defer.promise;
        }

        function pollCTV (oobaId, secureUrlId) {
            const options = {
                noCache: true
            };

            let data = {};
            data.secureActionUrlUd = secureUrlId;

            let defer = $q.defer();
            csrfTokenService.getTokenForSession().then(
                (token) => {
                    data.csrfToken = token;
                    $http.post(getUrl(oobaId + '/actions/poll'), data, options).then(
                        (res) => {
                            if (res.data && res.data.data) {
                                defer.resolve(res.data);
                            } else {
                                defer.reject(res.data);
                            }
                        },
                        (res) => {
                            defer.reject(res.data);
                        }
                    );
                },
                (res) => {
                    defer.reject(res.data);
                }
            );

            return defer.promise;
        }

        function cancelAuthentication (oobaId, secureUrlId) {
            const options = {
                noCache: true
            };

            let data = {};
            data.secureActionUrlUd = secureUrlId;

            let defer = $q.defer();
            csrfTokenService.getTokenForSession().then(
                (token) => {
                    data.csrfToken = token;
                    $http.post(getUrl(oobaId + '/actions/cancel'), data, options).then(
                        (res) => {
                            if (res.data.messages && res.data.messages.length > 0) {
                                defer.reject(res.data);
                            } else {
                                defer.resolve();
                            }
                        },
                        (res) => {
                            defer.reject(res.data);
                        }
                    );
                },
                (res) => {
                    defer.reject(res.data);
                }
            );

            return defer.promise;
        }

        function getCTVStatus () {
            const options = {
                noCache: true
            };

            return $http.get(getUrl('status'), options).then((res) => {
                return res.data;
            });
        }

        return {
            getOobaId: getOobaId,
            getCTVInfo: getCTVInfo,
            initiateCTV: initiateCTV,
			initiatePush: initiatePush,
            verifyTextCode: verifyTextCode,
            getCTVStatus: getCTVStatus,
            pollCTV: pollCTV,
            cancelAuthentication: cancelAuthentication
        };
    }];
