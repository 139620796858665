"use strict";

/**
 * Custom input with boiler plate code that all inputs should have e.g label tag, error, inputs.
 * Required attributes are ng-model, input-id and label
 *
 * Example:
 *    <form name="pForm">
 *        <svb-input ng-Model="pForm.sortCode" label="payment.sortCode" input-id="payment-sortCode">
 *    </form>
 *
 */

module.exports = ["directiveValidationService", "$filter", "$timeout",
    function svbInput(directiveValidationService, $filter, $timeout) {
    return {
        restrict: "E",
        require: "ngModel",
        scope: {
            disableInput: "=",
            inputId: "@",
            placeHolder: "@?",
            translationStr: "@label",
            filterName: "@",
            errorAtBottom: "=?",
            showErrorUntouched: "=?",
            allowClear: "=?",
            value: "=ngModel",
            onInputChange: "=?",
            eventModelUpdateOn: "@",
            callback: "=?"
        },
        transclude: {
            'icon': '?inputIcon',
            'maskDisplay': '?maskDisplay'
        },
        templateUrl: "core/modules/formElements/input/inputDirective.html",
        controller: ['$scope', function ($scope) {
            $scope.eventUpdate = {
                updateOn: $scope.eventModelUpdateOn || 'blur'
            };
        }],
        link: function (scope, element, attrs, ngModel, transclude) {
            directiveValidationService.enforceRequiredAttributes("svbInput", attrs, "inputId");

            scope.allowClear = scope.allowClear || false;
            scope.translationStr = scope.translationStr || undefined;
            scope.errorAtBottom = scope.errorAtBottom ? scope.errorAtBottom : false;
            scope.showErrorUntouched = scope.showErrorUntouched || false;
            scope.errorObj = ngModel.$error;
            scope.fieldName = ngModel.$name;
            scope.filterFn = scope.filterName ? $filter(scope.filterName) : angular.identity;

			scope.value = scope.value || "";
            scope.ngModelCtrl = ngModel;
            scope.isFocused = false;
            scope.isSlotFilled = transclude.isSlotFilled;

            ngModel.$formatters.push(function (val) {
                return scope.filterFn(val);
            });

            scope.shouldShowErrors = function () {
                return scope.showErrorUntouched ? ngModel.$invalid : ngModel.$touched && ngModel.$invalid;
            };

            scope.onFocus = function () {
                scope.isFocused = true;
            };

            scope.onBlur = function () {

                $timeout(() => {
                    ngModel.$setTouched();
                    if(this.callback && this.value && ngModel.$valid) {
                        this.callback();
                    }
                }, 0);

                scope.isFocused = false;

            };

            scope.clearInputFilter = function() {
                scope.value = "";
            };
        }
    };
}];
