"use strict";
var _ = require("lodash");

/**
 *
 * Service that parses the resource url configuration and provides a method for getting
 * a fully-qualified url for each defined resource
 *
 * @param apiBaseUrl The base url string that all service urls are relative to. Includes protocol.
 * @param resourceManifest Object defining the resources that the app can access
 * @constructor
 */

function UrlService() {
}

UrlService.prototype = {
    _getService: function (service) {
        return this.config.resourceManifest.services[service];
    },

    _getResource: function (resource) {
        return this.config.resourceManifest.resources[resource];
    },

    _getApiBaseUrl: function () {
        return this.config.apiBaseUrl;
    },

    /**
     *
     * Gives the url of a murano resource. Throws an error if no resource
     * by that name is configured.
     *
     * @param resourceName Name of resource as defined in resourceManifest
     * @returns {string} Fully-qualified url of resource
     */
    getUrlForResource: function getUrlForResource(resourceName) {
        var resourceConfig = this._getResource(resourceName);

        if (!resourceConfig) {
            throw new Error("No url configured for requested resource: " + resourceName);
        }

        return this._getApiBaseUrl() +
            this._getService(resourceConfig.service) +
            resourceConfig.path;
    },

    getPropertyFromUrl: function getPropertyFromUrl(endpoint, property) {
        let resource = Object.keys(this.config.resourceManifest.resources).find((resource) => {
            let path = this.config.resourceManifest.resources[resource].path;

            return endpoint.endsWith(path);
        });

        // If we don't have a resource configured or it's missing the property then CSRF should be performed
        if (!resource || !this.config.resourceManifest.resources[resource].hasOwnProperty(property)) {
            return null;
        }

        return this.config.resourceManifest.resources[resource][property];
    },

    /**
     * Ensures that each resource specified in the resource manifest
     * has all required properties and that it refers to a service that
     * exists in the manifest.
     */
    validate: function validate(config) {
        if (config.apiBaseUrl === undefined) {
            throw new Error("URL config validation error: config does not specify base url");
        }

        _.each(config.resourceManifest.resources, function (resourceConfig, name) {
            var requiredProps = ["service", "path"];
            requiredProps.forEach(function (propName) {
                if (!_.has(resourceConfig, propName)) {
                    throw new Error("URL config validation error: " + name +
                        " is missing a required property: " + propName);
                }
            });

            if (!config.resourceManifest.services[resourceConfig.service]) {
                throw new Error("URL config validation error: " + name +
                    " specified service that is not configured: " +
                    resourceConfig.service);
            }
        });
    }
};

module.exports = ["configService", function (configService) {
    var config = configService.getConfig();
    var urlService = new UrlService();

    //Validate the config now. Will cause entire app to fail to start if
    //resources are misconfigured
    urlService.validate(config);

    // Set the config, once it has been validated
    urlService.config = config;

    return urlService;
}];