"use strict";

let moduleDependencies = [
	"ui.select",
	'pascalprecht.translate'
];

angular.module("svb.formElements", moduleDependencies)
	.config(['$provide', require("./datepicker/dayNamesFormatter")])
	.config(require("./locales/locale"))
	.directive("svbInput", require("./input/inputDirective"))
	.directive("svbFormInput", require("./formInput/formInput"))
	.directive("svbSelect", ["directiveValidationService", require("./select/selectDirective")])
	.component("svbDatepicker", require("./datepicker/datepicker"));

module.exports = "svb.formElements";