"use strict";

var moduleName = "svb-modal-service";

function modalService($uibModal, $timeout) {
    return {
        openActionModal,
        openErrorModal
    };

    function openActionModal(options) {
        var windowClass = "mur-action-modal";

        if (options.noOpenAnimation) {
            windowClass += " no-animation";
        }

        angular.extend(options, {
            animation: true,
            windowClass: windowClass,
            backdrop: 'static'
        });

        var result = $uibModal.open(options);

        result.rendered.finally(function () {
            angular.element(document.querySelectorAll(".mur-action-modal")).removeClass("no-animation");

            if (options.noCloseAnimation) {
                $timeout(() => {
                    angular.element(document.querySelectorAll(".mur-action-modal")).addClass("no-animation");
                }, 500);
            }
        });

        return result;
    }

    function openErrorModal(options = {}) {
        if (!options.templateUrl) {
            options.template = "<svb-error-modal></svb-error-modal>";
        }

        return openActionModal(options);
    }
}

angular.module(moduleName, [
    require("angular-ui-bootstrap")
]).factory("modalService", ["$uibModal", "$timeout", modalService]);
module.exports = moduleName;
