"use strict";

let moduleDependencies = [
    require("angular-moment"),
    require("./csrf/csrfService"),
    require("./csrf/csrfResource"),
    require("./modalService/modalService")
];

angular.module("svb.services", moduleDependencies)
    .constant("moment", require('moment-timezone'))
    .factory("directiveValidationService", require("./directiveValidationService/directiveValidationServiceFactory"))
    .factory("configService", require("./configService/configServiceFactory"))
    .factory("urlService", require("./urlService/urlServiceFactory"))
	.factory("HeaderData", require("./headerData/headerDataResourceFactory"))
	.factory("SlimUserPreference", require("./userPreference/userPreferences.service"))
    .factory("promiseService", require("./promiseService/promiseServiceFactory"))
    .factory("errorMessageService", require("./errorMessageService/errorMessageServiceFactory"))
    .factory("csrfTokenService", require("./csrf/csrfTokenService"))
    .factory("matchMediaService", require("./matchMediaService"))
    .factory("mobileCheckService", ["$window", require("./mobileCheckService/mobileCheckService")]);

module.exports = "svb.services";