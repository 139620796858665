'use strict';

const windowHelper = require("../../utils/windowHelper");

module.exports = ["configService", function (configService) {
    "ngInject";
    let config = configService.getConfig();

    return {
        templateUrl: "core/modules/errorHandler/errorHandlerView.html",
        link: function (scope) {
            scope.redirect = function() {
                windowHelper.locationReplace(config.eConnectUrl + "/useraccess/mainmenu.do");
            };
        }
    };
}];