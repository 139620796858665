"use strict";

var directiveFactory = require("./validatorDirectiveFactory");

var moduleDependencies = [];

angular.module("svb.validation", moduleDependencies)
//Initial rule for user entered text fields: letters, numbers, spaces and any of: - / & .  '
//Should probably be replaced once we have specific rules for individual fields
    .directive("restrictedCharSet", directiveFactory("restricted-char-set", /^[a-zA-Z0-9 \-'+*#$%":~=;/&.()!@]*$/))
    .directive("zip", directiveFactory("zip", /^[a-zA-Z0-9\- ]*$/))
    .directive("numeric", directiveFactory("numeric", /^(\s*|\d+)$/))
    .directive("money", directiveFactory("money", /^[\,\][\d]{0,14}(\.\d{1,2})?$/))
    .directive("phone", directiveFactory("phone", /^[a-zA-Z0-9 \-\.'()+*#]*$/))
    .directive("nonZero", directiveFactory("nonZero", /[^0^.]+/));

module.exports = "svb.validation";