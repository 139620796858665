'use strict';

// This directive is used to display a button that shows a sub menu on hover
module.exports = [
    "$translate", "$filter", "$window", "$timeout",
    function ($translate, $filter, $window, $timeout) {
        return {
            scope: {
                // The nav hierarchy for this menu button, in the form:
                // {	//NOSONAR
                //    "rel": "string", // An unique string for this button
                //    "href": "string", // An optional link for this button
                //    "properties": "object" // Additional properties related to the button, see below
                // }	//NOSONAR
                //
                // The properties object is in the format
                // {	//NOSONAR
                //    "tag": ["string"], // Keywords associated with the button for searching
                //    "label": "string" // The label for the button
                // }	//NOSONAR
                hierarchy: "=",
                model: "=",
                entitlements: "="
            },
            replace: true,
            templateUrl: "core/modules/navbar/hoverMenuButton/hoverMenuButton.html",
            link: function (scope, element) {
                var SELECTED_CLASS = "selected";
                var ukServicesTranslation = $translate.instant("svb.navbar.ukTabName"),
                    homeLabelTranslation = $translate.instant("svb.navbar.homeTabName"),
                    fxTradingLabelTranslation = $translate.instant("svb.navbar.fxTradingTabName");

                if (!scope.model) {
                    scope.model = {};
                }

                scope.model.menuId = constructId();
                scope.model.selectMenu = select;
                scope.model.unselectMenu = unselect;
                scope.model.isMenuSelected = isSelected;
                scope.model.isUkServiceLabel = isUkServiceLabel;
                scope.model.buildLinkAddress = buildLinkAddress;

                function isUkServiceLabel(label) {
                    return label === ukServicesTranslation;
                }

                var unregisterHoverEvent = scope.$on('hide-hover-menu', () => {
                    var hoverMenu = element.querySelectorAll('.hover-menu');
                    hoverMenu.addClass('hide');
                    $timeout(() => {
                        hoverMenu.removeClass('hide');
                    }, 600);
                });

                scope.$on('$destroy', function () {
                    unregisterHoverEvent();
                });

                function buildLinkAddress(hierarchy) {
                    var isHomeLabel = hierarchy.properties.label === homeLabelTranslation,
                        isUkServicesLabel = hierarchy.properties.label === ukServicesTranslation,
                        isFxTradingLabel = hierarchy.properties.label === fxTradingLabelTranslation;

                    var result = '';
                    if ((hierarchy.href && isUkServicesLabel) || isHomeLabel || isFxTradingLabel) {
                        // For Murano users display Murano dashboard
                        result = isHomeLabel && scope.entitlements && scope.entitlements.murano ?
                            $window.location.pathname /* home */ :
                            $filter("decodeHTML")($filter("prependEconnectUrl")(hierarchy.href));
                    }

                    return result;
                }

                function constructId() {
                    return "headerMenuId" + scope.hierarchy.properties.menuId;
                }

                function select() {
                    getAnchor().classList.add(SELECTED_CLASS);
                }

                function unselect() {
                    getAnchor().classList.remove(SELECTED_CLASS);
                }

                function isSelected() {
                    return getAnchor().classList.contains(SELECTED_CLASS);
                }

                function getAnchor() {
                    return element ? element.find("a")[0] : null;
                }
            }
        };
    }];
