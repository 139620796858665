var moment = require("moment-timezone");

const linkTypes = /tel:|mailto:/;

// Taken from linkDirective.js
function openNewWindow(url, name, options) {
    var stringOpts = "scrollbars=yes,resizable=yes,status=yes,toolbar=no";

    // Iterate over the options and convert them to a single string
    if (options) {
        Object.keys(options).forEach(function (key) {
            stringOpts += "," + key + "=" + options[key];
        });
    }

    window.open(url, name, stringOpts);
}

module.exports = function () {
    return {
        restrict: 'A',
        replace: true,
        templateUrl: 'core/modules/navbar/mobileMenu/mobileMenuView.html',
        scope: {
            headerDataPromise: '=',
            active: '='
        },
        controller: [
            '$rootScope', '$scope', '$q', '$filter', '$timeout', '$translate', 'configService', 'promiseService', "oobaModalService", "authenticationDataService","$window", "$ocLazyLoad",
            function ($rootScope, $scope, $q, $filter, $timeout, $translate, configService, promiseService, oobaModalService, authenticationDataService,$window, $ocLazyLoad) {
                var config = configService.getConfig();

                /**
                 * Normalized list of links
                 *
                 * @type {Array}
                 */
                $scope.items = [];

                /**
                 * Stack of active sections
                 *
                 * @type {Array<Object>}
                 */
                $scope.activePath = [];

                $scope.$on('mobileMenu:toggle', function () {
                    $scope.active = !$scope.active;
                });

                $scope.$on('mobileMenu:show', function () {
                    $scope.active = true;
                });

                $scope.$on('mobileMenu:hide', function () {
                    $scope.active = false;
                });

                $scope.$watch('active', function () {
                    // Keep animation when closing while subsection is open
                    $timeout(function () {
                        if ($scope.active === true) {
                            $scope.$emit('mobileMenu:shown');
                        } else {
                            $scope.$emit('mobileMenu:hidden');

                            // Pop all active sections
                            while ($scope.activePath.length > 0) {
                                $scope.pop();
                            }
                        }
                    }, 200);
                });

                /**
                 * Creates profile menu
                 *
                 * @param {Object} response
                 * @returns {{ properties: { label: string }, links: Array }}
                 */
                function generateProfileMenu(response) {
                    return {
                        properties: {
                            label: response.name.givenName + ' ' + response.name.familyName
                        },
                        links: response.myProfile ? response.myProfile.links : []
                    };
                }

                function openNewTab(url, target) {
                    $window.open(url, target);
                }

                /**
                 * Creates structured menu from contacts data object
                 *
                 * @param {Object} contactsData
                 * @returns {{ properties: { label: string }, links: Array }}
                 */
                function generateContactsMenu(contactsData) {
                    var contacts = contactsData.contactInfo;

                    var menu = {
                        properties: {
                            label: $translate.instant('svb.navbar.contactUs')
                        },
                        links: []
                    };

                    angular.forEach(contacts, function (contact) {
                        var link = {
                            properties: {
                                label: $translate.instant('svb.navbar.' + contact.service)
                            },
                            links: []
                        };

                        // Add email
                        link.links.push({
                            properties: {
                                label: contact.email
                            },
                            href: 'mailto:' + contact.email
                        });

                        // Add phone numbers
                        angular.forEach(contact.phoneNumbers, function (phone) {
                            link.links.push({
                                properties: {
                                    label: phone, icon: 'svb-icon-phone'
                                },
                                href: 'tel:' + (phone.split(' ')[0]) // Cut non-phone data
                            });
                        });

                        menu.links.push(link);
                    });

                    // Add 'other contacts' link
                    menu.links.push({
                        properties: {
                            label: $translate.instant(contactsData.extraInfo.key)
                        },
                        href: contactsData.extraInfo.href
                    });

                    return menu;
                }

                /**
                 * Creates logout link
                 *
                 * @returns {{ properties: { label: string }, href: string }}
                 */
                function generateLogoutLink() {
                    return {
                        properties: {
                            label: $translate.instant('svb.navbar.logout')
                        },
                        href: config.eConnectLogoutUrl
                    };
                }

                // Load contacts and user data
                var mainPromise = $scope.headerDataPromise.then(function (response) {
                    var contacts = config.staticContactUsInfo;

                    // Set last login timestamp
                    $scope.lastLoginTimestamp = moment(response.currentTime).format('DD MMM YYYY hh:mm A');

                    // Combine navigational, profile, contact and logout links
                    $scope.items = response.navigation
                        .concat({
                            properties: {
                                separator: true
                            }
                        })
                        .concat(generateProfileMenu(response))
                        .concat(generateContactsMenu(contacts))
                        .concat(generateLogoutLink());

                    return $timeout(Function.prototype, 0);
                });

                promiseService.register(mainPromise, "fullPage");

                /**
                 * Adds new section to stack
                 *
                 * @param {Object} section
                 */
                $scope.push = function (section) {
                    $scope.activePath.push(section);
                };

                /**
                 * Removes last section from top of stack
                 */
                $scope.pop = function () {
                    $scope.activePath.pop();
                };

                /**
                 * Opens link if item has `href` property,
                 * shows section with children links otherwise
                 *
                 * @see linkDirective.js
                 * @param {Object} item
                 */
                $scope.open = function (item) {
                    if (item.href) {
                        let href = $filter('prependEconnectUrl')(item.href);

                        let handleAuthenticated = function () {
                            // Perform modal window content
                            if (href.match(/\.tpl$/)) {
                                $ocLazyLoad.load(configService.getConfig().creditCardAssets).finally(function () {
                                    var link = href.replace(config.eConnectUrl, '');
                                    if (link === config.staticCreditCardLinks.registerCard.href) {
                                        registerCard(link);
                                    } else {
                                        $rootScope.$broadcast('svb.econnect.content.pull');
                                        contentPull(link, item.section, item.properties.label);
                                    }
                                });
                            } else if (item.newWindow) {
                                openNewWindow(href, item.newWindow, {
                                    width: 700,
                                    height: 400,
                                    left: 310,
                                    top: 0,
                                    menubar: 'yes'
                                });
                            } else if (item.linkTarget) {
                                openNewTab(href, item.linkTarget);
                            } else if (linkTypes.test(item.href)) {
                                $window.location.href = item.href;
                            } else {
                                if (href === '#/home') {
                                    href = config.baseUrl;
                                }

                                $window.location.href = href;
                            }
                        };

                        if (item.securedURL && item.securedURL !== false) {

							authenticationDataService.getCTVStatus().then((response) => {
								let oobaData = response && response.data ? response.data : null;

							if (!oobaData || !oobaData.enabled || oobaData.isOobaAuthenticated) {
								handleAuthenticated();
							} else {
								$scope._promise = oobaModalService.beginOOBAWorkflow().then(() => handleAuthenticated());
							}
						});

                        } else {
                            handleAuthenticated();
                        }
                    } else {
                        // Push section to stack
                        $scope.push(item);
                    }
                };

                /**
                 * Hides mobile menu
                 */
                $scope.hide = function () {
                    $scope.active = false;
                };
            }
        ]
    };
};