"use strict";

var angularResource = require("angular-resource");

var moduleName = "svb-csrf-resource";

angular.module(moduleName, [angularResource]).factory("CSRF",
    ["$resource", "urlService", ($resource, urlService) => {
        return $resource(urlService.getUrlForResource("csrfToken"));
    }]
);

module.exports = moduleName;