"use strict";

/**
 * Directive dedicated to handle error messages for different types of components(ex. inputs, selectdDropdowns).
 * Expects an error model from a model controller and the name of the field. The field name
 * is used to look up the appropriate error messages
 *
 *
 * Example:
 *    <error-message field-name="fieldName" errors="myForm.fieldName.$error"></error-message>
 */

module.exports = {
    bindings: {
        fieldName: "@",
        errorObj: "=errors"
    },
    templateUrl: "core/modules/errorMessage/errorMessageComponent.html",
    controllerAs: "$errorMsgCtrl",
    controller: ["errorMessageService", function (errorMessageService) {
        this.getErrorMessages = function () {
            return errorMessageService.getErrMsgs(this.fieldName);
        };
    }]
};