/**
 * Observable class
 *
 * @example
 * const myObservable = new Observable();
 *
 * myObservable.subscribe(event => console.log(event));
 *
 * myObservable.deliver({ foo: 'bar' });
 */
export default class Observable {

    /**
     * List of subscribed observers
     *
     * @type {Array<Function>}
     */
    observers = [];

    /**
     * @param {Function} observer
     * @returns {Observable}
     */
    subscribe(observer) {
        if (typeof observer !== 'function') {
            throw new TypeError('Observer must be a function');
        }

        this.observers.push(observer);

        return this;
    }

    /**
     * @param {Function} observer
     * @returns {Observable}
     */
    unsubscribe(observer) {
        const index = this.observers.indexOf(observer);

        if (index < 0) {
            throw new TypeError('No such observer were subscribed');
        }

        this.observers.splice(index, 1);

        return this;
    }

    /**
     * @param {?Object} data
     * @return {Boolean} true if data is delivered at least to one observer, false otherwise
     */
    deliver(data) {

        // If there's no subscribed observers yet
        if (this.observers.length === 0) {
            return false;
        }

        // Deliver data
        for (let i = 0; i < this.observers.length; i++) {
            this.observers[i](data);
        }

        return true;
    }
}