'use strict';

// A custom a tag which ensures that will trigger the authenticate flow when necessary
module.exports = ["$http", "$filter", "$rootScope", "$window", "$document", "$location", "$log", "oobaModalService", "authenticationDataService", "configService", "$ocLazyLoad",
    function ($http, $filter, $rootScope, $window, $document, $location, $log, oobaModalService, authenticationDataService, configService, $ocLazyLoad) {
        var config = configService.getConfig();

        // Open a link in a new browser $window
        var openNewWindow = function (url, name, options) {
            var stringOpts = "scrollbars=yes,resizable=yes,status=yes,toolbar=no";

            // Iterate over the options and convert them to a single string
            if (options) {
                Object.keys(options).forEach(function (key) {
                    stringOpts += "," + key + "=" + options[key];
                });
            }

            $window.open(url, name, stringOpts);
        };

        var openNewTab = function (url, target) {
            $window.open(url, target);
        };

        return {
            scope: {
                // The destination URL for the link
                "linkHref": "=",
                "newWindow": "=",
                "linkTarget": "=",
                "section": "=",
                "linkLabel": "=",
                "linkId": "@",
                "isNotNavLink": "@",
                "securedUrlId": "=",
                "securedUrl": "=",
				"shouldDisable": "=",
				"isDisabled": "=?"
            },
            template: '<a ng-attr-id="{{linkId}}" ng-click="onClick()" ng-class="{disabledLink: isDisabled}"><ng-transclude></ng-transclude></a>',
            replace: true,
            transclude: true,
            link: function (scope) {
				scope.isDisabled = false;

                scope.onClick = function () {
					if (scope.isDisabled) {
						return;
					}
					if (scope.shouldDisable) {
						scope.isDisabled = true;
					}

                    function handleAuthenticated() {
                        if ((scope.linkHref || "").match(/\.tpl$/)) {
                            $ocLazyLoad.load(configService.getConfig().creditCardAssets).finally(function () {
                                // Any href ending in ".tpl" is an external html template that needs to be loaded
                                // into the dom
                                var href = scope.linkHref.replace(config.eConnectUrl, "");

                                if (href === config.staticCreditCardLinks.registerCard.href) {
                                    registerCard(href);
                                } else {
                                    // Hide Murano banner, popups, etc. before loading content using eConnect's mechanisms
                                    $rootScope.$broadcast('svb.econnect.content.pull');
                                    contentPull(href, scope.section, scope.linkLabel);
                                }
                            });
                        } else if (scope.newWindow) {
                            // These properties are copied from eConnect in order to duplicate the link functionality
                            openNewWindow($filter("prependEconnectUrl")(scope.linkHref), scope.newWindow, {
                                width: 700,
                                height: 400,
                                left: 310,
                                top: 0,
                                menubar: "yes"
                            });
                        } else if (scope.linkTarget) {
                            openNewTab($filter("prependEconnectUrl")(scope.linkHref), scope.linkTarget);
                        } else {
                            return doNav();
                        }
                    }

                    function doNav() {
                        if (scope.linkHref === '#/home') {
                            scope.linkHref = config.baseUrl;
                        }

                        $window.location.href = scope.linkHref;
                    }

                    if (scope.securedUrl && scope.securedUrl !== false) {
                        authenticationDataService.getCTVStatus().then((response) => {
                            let oobaData = response && response.data ? response.data : null;

                            if (!oobaData || !oobaData.enabled || oobaData.isOobaAuthenticated) {
                                handleAuthenticated();
                            } else {
                                oobaModalService.beginOOBAWorkflow(null, scope.securedUrlId).then(handleAuthenticated).catch(() => {
									scope.isDisabled = false;
								});
                            }
                        });
                    }
                    else {
                        return handleAuthenticated();
                    }
                };
            }
        };
    }
];
