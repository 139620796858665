"use strict";

module.exports = {
    authenticatePollingInterval: 5000,
    staticHeaderLinks: [
        {
            key: "svb.navbar.help",
            href: "https://www.svb.com/learning_center/onlinebanking/",
            newWindow: "SVBEHELP"
        },
        {
            key: "svb.navbar.mainMenu",
            href: "/useraccess/Mainmenu.jsp"
        }
    ],
    resourceManifest: {
        services: {
			dashboard: "/muranosvc/rest",
			csrf: "/csrf/service/rest/csrf",
            entitlements: "/entitlement/service/rest",
            ooba: "/ooba/service/rest/ooba"
        },
        resources: {
            csrfToken: {
                service: "csrf",
                path: "/v1/token"
            },
            headerData: {
                service: "entitlements",
                path: "/entitlement/v1/headerData"
            },
			ooba: {
                service: "ooba",
                path: "/v1/"
            },
			userPreferences: {
				service: "dashboard",
				path: "/userpreferences/v1/widgets"
			}
        }
    },
    staticContactUsInfo: {
        contactInfo: [
            {
                country: "unitedStates",
                phoneNumbers: ["1.800.774.7390", "1.408.654.5555"],
                email: "clientsupport@svb.com",
                openHours: "5:00 a.m. - 5:30 p.m. PT M-F",
                service: "unitedStates"
            }
        ],
        businessAndMultiCard: {
            country: "unitedStates",
            phoneNumbers: ["1.866.553.3481", "001.408.654.1039"],
            email: "cardservices@svb.com",
            openHours: "24 hours a day, 7 days a week"
        },
        businessEliteCard: {
            country: "unitedStates",
            phoneNumbers: ["1.866.940.5920", "408.654.7720"],
            email: "cardservices@svb.com",
            openHours: "24 hours a day, 7 days a week"
        },
        extraInfo: {
            key: "svb.navbar.moreContacts",
            href: "https://www.svb.com/contact-support/"
        }
    },
	muranoTimeoutInfo: {
		usTimeout: 900,
		ukTimeout: 300,
		logoutUrl : "https://www.svbconnect.com/auth/",
		timerInterval: 60000
	},
    staticCreditCardLinks: {
        topLevel: "Account",
        registerCard: {
            key: "svb.creditCards.registerMyCard",
            href: "/cc/views/ccRegister.tpl",
            label: "Register"
        },
        viewActivity: {
            key: "svb.creditCards.viewActivity",
            href: "/cc/views/ccActivity.tpl",
            label: "Activity"
        },
        makePayment: {
            key: "svb.creditCards.makePayment",
            href: "/cc/views/ccPayments.tpl",
            label: "Payments"
        }
    },
    timeouts: {
        authCodeExpirationTime: 240000
    },
    staticWireCutOffTime: {
        data: [
            {
                wireType: "U.S. Dollar Wires <br> (Domestic and International) ",
                cutOffTime: ["3:00 PM PT"]
            },
            {
                wireType: "In-Country Accounts (ICA)",
                cutOffTime: ["1:00 PM PT"]
            },
            {
                wireType: "Foreign Currency Wires ",
                cutOffTime: ["1:00 PM PT for Exotic currencies",
                    "2:00 PM PT for Standard currencies"]
            },
            {
                wireType: "FX Contract Purchase",
                cutOffTime: ["1:00 PM PT"]
            },
            {
                wireType: "Multicurrency Accounts (MCAs)",
                cutOffTime: ["1:00 PM PT"]
            },
            {
                wireType: "SVB Asset Management U.S. Dollar Outgoing Wires",
                cutOffTime: ["11:30 AM PT"]
            }
        ]
    },
    staticAuthenticationLinks: {
        info: {
            key: "svb.generic.clickHere",
            href: "https://www.svb.com/svbeconnect/resources/content/authenticate.html"
        },
        support: {
            key: "svb.authentication.supportCenter",
            href: "https://www.svb.com/contactus.aspx"
        },
        security: {
            key: "svb.generic.clickHere",
            href: "/security/credentialChange.do?cmdCredentialChange=20"
        }
    },
    creditCardAssets: [
        "/css/svb-global.css",
        "/css/svb-econnect.css",
        "/css/svb-econnect2.css",
        "/css/svbcurrent/jquery-ui-1.9.2.custom.css",
        "/css/ctv-1.0.css",
        "/cc/resources/css/ccStyles.css",
        "/cc/resources/css/svb-responsive.css"
    ],
    wireApprovalHref: "/wires/approveWire.do?context=approveWireView&ApprovalViewSource=3",
    wireInquiryHref: "/wires/outgoing/wireinqcriteria.jsp?headerParam=Wires",
    CCDateFormat: "DD MMM",
    appDateFormat: "dd MMM yyyy",
    accMaskingStr: "***",
    cache: "testing1"
};
