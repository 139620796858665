"use strict";
var windowHelper = require("../../utils/windowHelper");
var _ = require('lodash');
require("./navbarModule.js");
var ecCcUtils = require("../../assets/js/ec-cc-utils");

module.exports = [
	"configService", "HeaderData", "$filter", "$rootScope", "promiseService", function
			(configService, HeaderData, $filter, $rootScope, promiseService) {
		return {
			templateUrl: "core/modules/navbar/navbar.html",
			controller: ["$scope", "$filter", "$stateParams", "$interval", function ($scope, $filter, $stateParams, $interval) {
				$scope.openWindow = function (url, name) {
					// These properties are copied from eConnect in order to duplicate the link functionality
					windowHelper.openNewWindow($filter("prependEconnectUrl")(url), name, {
						width: 700,
						height: 400,
						left: 310,
						top: 0,
						menubar: "yes"
					});
				};

                // Watch for state change events in order to hide the header if the state requests it
                $scope.$on('$stateChangeSuccess', () => {
                    $scope.showHeader = ($stateParams.showHeader === undefined ? true : $stateParams.showHeader);
                });
			var increaseCounter = function () {
				let timeDiff = 0;
				var muranoConfigs = configService.getConfig();
				if($rootScope.oldTime != undefined){
					timeDiff = parseInt((new Date() - new Date($rootScope.oldTime)) / 1000);
				}
				if ($rootScope.headerData.entitlements["UK User"] == undefined) {
                  if (timeDiff > muranoConfigs.muranoTimeoutInfo.usTimeout) {
                      window.location.replace(muranoConfigs.eConnectLogoutUrl);
                  }
				} else {
                  if (timeDiff > muranoConfigs.muranoTimeoutInfo.ukTimeout) {
                      window.location.replace(muranoConfigs.eConnectLogoutUrl);
                  }
				}
			};

			$interval(increaseCounter, 60000);
			}],
			link: function (scope) {
				var configs = configService.getConfig();
				// Mapping the tabName based on the moduleName
				// Tab names are hard-coded
				function getHeaderTabName(toState) {
                    var parts = toState ? toState.name.split('.') : [];
					var moduleName = parts.length > 0 ? parts[0] : "";

                    var tabMenuIdMap = {
                        "murano": "0",
                        "billpay": "2",
                        "payment": {
                            default: "47",
                            auditReport: '5017'
                        }
                    };

                    var menuId = tabMenuIdMap[moduleName];
                    if (_.isObject(menuId)) {
                        var stateName = parts.length > 1 ? parts[1] : "";
                        if (menuId[stateName]) {
                            menuId = menuId[stateName];
                        }
                        else {
                            menuId = menuId.default;
                        }
                    }
                    return menuId;
				}

				// change tab highlighting when state change starts.
				var unregisterStateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState) {
					var menuIdPrefix = "headerMenuId";
					var tabMenuId = menuIdPrefix + getHeaderTabName(toState);
					var tab = _.find(scope.navTabs, t => t.menuId === tabMenuId);
					if (tab && !tab.isMenuSelected()) {
						var currentSelectedTab = _.find(scope.navTabs, t => t.isMenuSelected());
						if (currentSelectedTab) {
							currentSelectedTab.unselectMenu();
						}

						tab.selectMenu();
					}
				});

				$rootScope.$on('$destroy', function () {
					unregisterStateChangeStart();
				});

				scope.eConnectLogoutUrl = configs.eConnectLogoutUrl;
				scope.staticHeaderLinks = configs.staticHeaderLinks;

				/**
				 * Toggle mobile menu
				 */
				scope.toggleMobileMenu = function () {
					scope.$broadcast('mobileMenu:toggle');
				};

				scope.headerDataPromise = HeaderData.get($rootScope).then(function (headerData) {
					scope.firstName = headerData.name.givenName;
					scope.lastName = headerData.name.familyName;
					scope.currentTime = headerData.currentTime ?
							$filter("date")(headerData.currentTime, "dd MMM yyyy hh:mm a") : "";
					scope.lastLogin = headerData.lastLogin;
					scope.navTabs = headerData.navigation;
					scope.myProfile = headerData.myProfile;
					scope.entitlements = headerData.entitlements;

					ecCcUtils(scope.navTabs);

					// Starts keep alive in dashboard, might be obsolete in the future:
					$rootScope.$broadcast("navControllerInit");

					if (scope.myProfile && scope.myProfile.links) {
						var alertLinks = _.find(scope.myProfile.links, function (linkInfo) {
							return (linkInfo.properties.menuId === 6010);
						});

						if (alertLinks && alertLinks.links && alertLinks.links.length) {
							alertLinks.links = _.first(alertLinks.links).links;
						}
					}

					if (headerData.cardUser) {
						scope.isCreditCardUser = headerData.cardUser.isCreditCardUser;
						scope.isBusinessEliteCardUser = headerData.cardUser.isBusinessEliteCardUser;
					}

					$rootScope.$broadcast('navScopeUpdate');
					window.sessionStorage.setItem("whatfix", JSON.stringify(headerData.navigation));
					window.sessionStorage.setItem("migration", JSON.stringify(headerData.migration));
                    return headerData;
				});

				promiseService.register(scope.headerDataPromise, "fullPage");
			}
		};
	}];
