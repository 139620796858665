"use strict";

let _ = require("lodash");

const APP_NAME = 'appSVB';

angular
	.module(APP_NAME)
	.config(function ($urlRouterProvider) {
		if (!global.envConfig.subProjecs) {
			let errMsg = "No subProjecs found";

			throw new Error(errMsg);
		}

		// Add all the subProject routes
		Object.keys(global.envConfig.subProjecs).forEach((key) => {
			if (!global.envConfig.subProjecs[key]) {
				return;
			}

			let [what, handler] = global.envConfig.subProjecs[key].urlMatcher;

			$urlRouterProvider.when(what, handler);
		});
	})
	.config(function ($stateProvider) {
		_.forOwn(global.envConfig.subProjecs, (subProject, moduleName) => {
			if (subProject) {
				let state = subProject.state;

				$stateProvider
					.state(moduleName, {
						url: state.baseUrl,
						abstract: true,
						template: '<ui-view/>',
						params: {
							module: moduleName
						},
						resolve: {
							allAssets: fetchAssets,
							headerData: getHeaderData
						}
					})
					.state(moduleName + ".core", {
						url: "/:state",
						params: {
							defaultState: state.default
						},
						resolve: {
							load: loadModule
						}
					});
			}
		});
	})
	.run(handleStateChange);

//need to make sure headerData call is completed.
getHeaderData.$inject = ['$q', '$log', 'HeaderData', 'SlimUserPreference', 'promiseService'];

function getHeaderData($q, $log, HeaderData, SlimUserPreference, promiseService) {
	return $q
		.all([HeaderData.get(), SlimUserPreference.get()])
		.catch(function (err) {
			errorHandler(err, $log, promiseService);

			if (err && err.config && err.config.url.indexOf("headerData") >= 0) {
				return $q.reject(err);
			}

			return err;
		});
}

// $$animateJs needs to be injected to fix a bug with $ocLazyLoad
// Without this, angular animate will log console errors for all animations
fetchAssets.$inject = ['$stateParams', '$log', 'configService', '$rootScope', '$ocLazyLoad', 'promiseService', '$$animateJs'];

function fetchAssets($stateParams, $log, configService, $rootScope, $ocLazyLoad, promiseService) {
	let assets = configService.getConfig().subProjecs[$stateParams.module];

	// This property is used to change body class
	$rootScope.moduleName = $stateParams.module;

	// Download the config file first, it is required to be able to load everyone Murano module
	// $ocLazyLoad caches files by default, these files will not be downloaded
	// https://oclazyload.readme.io/
	return $ocLazyLoad.load(assets.config).then(function () {
		return $ocLazyLoad.load(assets.files);
	}).catch(function (err) {
		return errorHandler(err, $log, promiseService);
	});
}

// The 'allAssets' injectable forces the parent state to resolve before continuing
loadModule.$inject = ['$stateParams', '$log', 'promiseService', '$state', 'allAssets', 'headerData'];

function loadModule($stateParams, $log, promiseService, $state) {
	let newState = $stateParams.module + "." + ($stateParams.state || $stateParams.defaultState);

	// Verify that the state exists before transitioning to it
	if (!$state.get(newState)) {
		let errMsg = "State: " + newState + " not found";

		return errorHandler(errMsg, $log, promiseService);
	}

	// This state will not exist in this application, but should exist in the loaded module
	var p = $state.go(newState, null, {reload: true});

	return promiseService.register(p, "fullPage").catch(function (err) {
		return errorHandler(err, $log, promiseService);
	});
}

handleStateChange.$inject = ['$rootScope', '$document'];

function handleStateChange($rootScope, $document) {
	$rootScope.$on('$stateChangeStart', onStateChange);

	function onStateChange(event, toState, toParams, fromState) {
		if (!fromState.abstract) {
			closeModals();
			closeCC();
		}
	}

	/**
	 * The requirement is any open modal shall be closed when transitioning to another page.
	 */
	function closeModals() {
		[].forEach.call($document.find('div'), function (div) {
			var element = angular.element(div);
			if (element.hasClass('modal') && !element.hasClass('in-remove')) {
				var scope = element.scope();
				if (typeof scope.$dismiss === 'function') {
					scope.$dismiss('State transition');
					element.remove(); // avoid "glitch" since $dismiss() removes the modal asynchronously
				}
			}
		});
	}

	function closeCC() {
		var cc = $document[0].getElementById('ec_breadcrumbs');
		if (cc && cc.firstChild) {
			cc.removeChild(cc.firstChild);
		}
	}
}

function errorHandler(errMsg, $log, promiseService) {
	promiseService.flush("bodySection", "Failed");

	$log.error(errMsg);
}