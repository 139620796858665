import Observable from "./Observable";

// Get RAF function via fallback for older browsers
let targetTime = 0;

const requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    (callback => {
        const currentTime = Date.now();
        const callbackTimeout = () => callback(Date.now());

        targetTime = Math.max(targetTime + 16, currentTime);

        return window.setTimeout(callbackTimeout, targetTime - currentTime);
    });

/**
 * Viewport class
 *
 * @example
 * // Subscribe to any window resize
 * Viewport
 *      .get()
 *      .subscribe(event => {
 *          console.log(event);
 *      });
 *
 * @example
 * // Subscribe to breakpoint changes
 * Viewport
 *      .get()
 *      .subscribeBreakpoint(breakpoint => {
 *          console.log('New breakpoint is ' + breakpoint);
 *      });
 *
 * @example
 * // Subscribe to stereotype changes
 * Viewport
 *      .get()
 *      .subscribeStereotype(stereotype => {
 *          console.log('New stereotype is ' + stereotype);
 *      });
 *
 * @example
 * // Subscribe to orientation changes
 * Viewport
 *      .get()
 *      .subscribeOrientation(orientation => {
 *          console.log('New orientation is ' + orientation);
 *      });
 */
export default class Viewport extends Observable {

    /**
     * Set of breakpoints and their max. width
     *
     * @type {Object}
     */
    static breakpoints = {

        // Extra small devices (portrait phones)
        xs: 575,

        // Small devices (landscape phones)
        sm: 767,

        // Medium devices (tablets)
        md: 991,

        // Large devices (desktops)
        lg: Infinity
    };

    /**
     * Set of stereotypes
     *
     * @type {Object}
     */
    static stereotypes = {
        1: ['xs', 'sm'],
        2: ['md', 'lg']
    };

    /**
     * Single instance of Viewport
     *
     * @type {?Viewport}
     */
    static instance = null;

    /**
     * Singleton getter
     *
     * @returns {Viewport}
     */
    static get() {
        if (Viewport.instance === null) {
            Viewport.instance = new Viewport();
        }

        return Viewport.instance;
    }

    /**
     * Window width of previous frame
     *
     * @type {number}
     */
    previousWindowWidth = -1;

    /**
     * Window height of previous frame
     *
     * @type {number}
     */
    previousWindowHeight = -1;

    constructor() {
        super();

        this._onFrameRender = this._onFrameRender.bind(this);
        this._onFrameRender();
    }

    /**
     * Returns breakpoint code based on specified width
     *
     * @param width
     * @returns {string}
     * @private
     */
    _getBreakpoint(width) {
        let breakpoint;

        for (const key in Viewport.breakpoints) {
            if (!Viewport.breakpoints.hasOwnProperty(key)) {
                continue;
            }

            breakpoint = key;

            if (width <= Viewport.breakpoints[key]) {
                return breakpoint;
            }
        }

        throw new Error('No suitable breakpoint found');
    }

    /**
     * Returns stereotype based on breakpoint code
     *
     * @param breakpoint
     * @returns {string}
     * @private
     */
    _getStereotype(breakpoint) {
        for (const key in Viewport.stereotypes) {
            if (!Viewport.stereotypes.hasOwnProperty(key)) {
                continue;
            }

            if (Viewport.stereotypes[key].indexOf(breakpoint) > -1) {
                return key;
            }
        }

        throw new Error('Unknown stereotype for specified breakpoint found');
    }

    /**
     * Returns current window width
     *
     * @returns {Number}
     * @private
     */
    _getWindowWidth() {
        return window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
    }

    /**
     * Returns current window height
     *
     * @returns {Number}
     * @private
     */
    _getWindowHeight() {
        return window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight
    }

    /**
     * Handles `requestAnimationFrame` callback
     *
     * @private
     */
    _onFrameRender() {
        const currentWindowWidth = this._getWindowWidth();
        const currentWindowHeight = this._getWindowHeight();

        if (currentWindowWidth !== this.previousWindowWidth ||
            currentWindowHeight !== this.previousWindowHeight) {
            const currentBreakpoint = this._getBreakpoint(currentWindowWidth);
            const currentStereotype = this._getStereotype(currentBreakpoint);

            const delivered = this.deliver({
                size: {
                    width: currentWindowWidth,
                    height: currentWindowHeight
                },
                breakpoint: currentBreakpoint,
                stereotype: currentStereotype,
                orientation: (currentWindowWidth >= currentWindowHeight) ? 'landscape' : 'portrait'
            });

            // Make sure at least one observer received data
            if (delivered === true) {
                this.previousWindowWidth = currentWindowWidth;
                this.previousWindowHeight = currentWindowHeight;
            }
        }

        requestAnimationFrame(this._onFrameRender);
    }

    /**
     * Subscribes to breakpoint changes
     *
     * @param {Function} observer
     * @returns {Observable}
     */
    subscribeBreakpoint(observer) {
        const breakpointObservable = new Observable();
        let previousBreakpoint = null;

        breakpointObservable.subscribe(observer);

        this.subscribe(data => {
            const currentBreakpoint = data.breakpoint;

            if (previousBreakpoint !== currentBreakpoint) {
                if (breakpointObservable.deliver(currentBreakpoint)) {
                    previousBreakpoint = currentBreakpoint;
                }
            }
        });

        return this;
    }

    /**
     * Subscribes to stereotype changes
     *
     * @param {Function} observer
     * @returns {Viewport}
     */
    subscribeStereotype(observer) {
        const stereotypeObservable = new Observable();
        let previousStereotype = null;

        stereotypeObservable.subscribe(observer);

        this.subscribe(data => {
            const currentStereotype = data.stereotype;

            if (previousStereotype !== currentStereotype) {
                if (stereotypeObservable.deliver(currentStereotype)) {
                    previousStereotype = currentStereotype;
                }
            }
        });

        return this;
    }

    /**
     * Subscribes to orientation changes
     *
     * @param {Function} observer
     * @returns {Viewport}
     */
    subscribeOrientation(observer) {
        const orientationObservable = new Observable();
        let previousOrientation = null;

        orientationObservable.subscribe(observer);

        this.subscribe(data => {
            const currentOrientation = data.orientation;

            if (previousOrientation !== currentOrientation) {
                if (orientationObservable.deliver(currentOrientation)) {
                    previousOrientation = currentOrientation;
                }
            }
        });

        return this;
    }
}

// Set `Viewport` as global property
window.Viewport = Viewport;