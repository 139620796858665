'use strict';

/**
 * Monkey patch browserify's require() to allow requirejs's require(). For example, Credit Card app uses requirejs.
 */
module.exports = requireJSLoader;

requireJSLoader.$inject = ['$q', '$document', '$window', 'configService'];

function requireJSLoader($q, $document, $window, configService) {
    var promise = null;
    var config = configService.getConfig();

    return {
        /**
         * Asynchronously load requireJS and patch global "require". After patching "require" would delegate to
         * "requireJS" if the first argument is an array or to browserify's "require" otherwise.
         * @return {Promise} - empty promise
         */
        load: load
    };

    // IMPLEMENTATION
    function load() {
        if (!promise) {
            var deferred = $q.defer();
            promise = deferred.promise;

            // Load requireJS
            var brfsRequire = $window.require;
            var loadRequireJS = $document[0].createElement('script');
            loadRequireJS.src = config.baseUrl + '/assets/js/require.js';
            loadRequireJS.onload = ((brfsRequire) => {
                patch(brfsRequire);
                deferred.resolve();
            }).bind(null, brfsRequire);

            $window.require = undefined; // require shall be undefined to properly load requireJS
            $document.find('body').append(loadRequireJS);
        }

        return promise;
    }

    function patch(brfsRequire) {
        $window.require = function () {
            if (Object.prototype.toString.call(arguments[0]) === '[object Array]') {
                return $window.requirejs.apply($window, arguments);
            } else {
                return brfsRequire.apply($window, arguments);
            }
        };

        // Assign static requireJS's methods to global require
        require('lodash').assign($window.require, $window.requirejs);
        $window.requireCA = true; // indicates requireJS needs CC configuration
    }
}