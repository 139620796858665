"use strict";

module.exports = {
    BUSINESS_CHECKING: "10",
    ANALYSIS_CHECKING: "11",
    BUSINESS_CHECKING2: "14",
    NOW_ACCOUNT: "100",
    SUPER_NOW: "101",
    SUPER_NOW2: "103",
    BANKERS_ACCEPTANCE: "110",
    INTEREST_CHECKING: "115",
    NOW_ACCOUNT2: "121"
};