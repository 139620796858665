class ExpandSectionComponent {
	constructor($scope) {
		this.isCollapsed = this.autoExpand ? !this.autoExpand : true;
		this.children = [];
		this.$scope = $scope;
	}

	$onInit() {
		if (this.parent) {
			this.parent.push(this);
		} else {
			// Toggle all parent expandSections only, by default children will be toggled.
			this.$scope.$on("svb.expandSection.parent", (event, toggleState) => {
				this.toggleSection(toggleState);
			});

			// Emit the state of the top most parent containers.
			// Easily determines if all the sections are expanded.
			this.$scope.$on("svb.expandSection.checkCount", () => {
				this._reportSection(!this.areSomeCollapsed());
			});

			this._reportSection((this.hasOwnProperty("disableFlag") ? false : this.lockOpen) || this.disableDefaultExpand);
		}
	}

	_reportSection(state) {
		this.$scope.$emit("svb.expandSection.currentState", this.label, state);
	}

	toggleSection(collapse) {
		// Only expand/collapse the children, not the parent.
		if (this.children.length > 0) {
			this.children.forEach((child) => {
				child.isCollapsed = collapse;
			});
		} else {
			this.isCollapsed = collapse;
		}

		// Check if this change has caused all the sections to be expanded/collapsed.
		this.$scope.$emit("svb.expandSection.checkCount");
	}

	areSomeCollapsed() {
		if (this.children.length > 0) {
			return this.children.reduce((prev, curr) => (prev || (!curr.disableFlag && curr.isCollapsed)), false);
		}

		return this.isCollapsed;
	}
}

export default angular
	.module("svb-expand-section", [
		require("angular-translate")
	])
	.component("svbExpandSection", {
		restrict: "E",
		replace: true,
		bindings: {
			label: "@", // Header label, can be an i18n key
			headerClass: "@", // Custom css class for the header
			disableFlag: "<?", // Allow the expand feature to be disabled, will render DOM normally
			disableDefaultExpand: "<?", // Allow the expand feature to be disabled, will render DOM normally
			autoExpand: "<?", // Automatically expand the section by default
			hideSubLabel: "<?", // Disable the sub label section
			lockOpen: "<?", // Prevent toggling the section
			parent: "=", // Hook for child to add itself to the parent expand/collapse
			isCollapsed: "=?"
		},
		transclude: {
			'expandSection': 'expandSection',
			'subHeaderSection': '?subHeaderSection',
			'subLabelSection': '?subLabelSection'
		},
		templateUrl: "core/modules/widgets/expandSection/expandSection.html",
		controller: ExpandSectionComponent,
		controllerAs: 'expandCtrl',
		bindToController: true
	})
	.name;