"use strict";

const _ = require('lodash');

module.exports = function ($uibModal, $filter, $rootScope, $log,		// NOSONAR - function too long
                            authenticationDataService,configService, ctvConstants, $q) {	// NOSONAR - function too long
    "ngInject";
    let locked = false;

    const TEMPLATE = {
        DEFAULT_MODAL: "core/modules/ooba/modalView.html",
        AUTH_MODAL: "core/modules/ooba/authenticationModalView.html",
        AUTH_SUCCESS_MODAL: "core/modules/ooba/authenticationSuccessModalView.html",
        AUTH_ERROR_LOGOUT_MODAL: "core/modules/ooba/authenticationErrorLogoutModalView.html",
        AUTH_ERROR_MODAL: "core/modules/ooba/authenticationErrorModalView.html"
    };

    const CONFIG = {
        modalAnimation: true
    };

    function openModal (options, windowClass, backdropClass, modalTemplate, modalController) {
        // Only allow one modal to be open at a time
        if (locked) {
            return;
        }

        locked = true;

        const modalOptions = {
            controller: modalController || "ModalController",
            templateUrl: modalTemplate || TEMPLATE.DEFAULT_MODAL,
            backdrop: "static",
            windowClass: windowClass || "" + ' ctv-modal',
            backdropClass: backdropClass || "",
            keyboard: false,
            animation: CONFIG.modalAnimation,
            resolve: {
                options: () => {
                    return options;
                }
            }
        };

        let instance = $uibModal.open(modalOptions);

        instance.result.finally(() => {
            // After modal is closed, allow more to be opened
            locked = false;
        });

        return instance;
    }

    /**
     * @param getPromise - if modal is being shown as a second call to the getNotifications endpoint
     * is being made, this will be a promise that will be resolved with the response from the service,
     * otherwise it will be undefined
     */
    function ModalService() {		// NOSONAR (relex Function names should comply with a naming convention)
    }

    /**
     * This function creates the help text to showup on the CTV modal window
     * @param scope
     * @param filter
     * @param config
     */
    function setStaticLinks (scope, filter, ctvConstants) {
        let links = ctvConstants.staticAuthenticationLinks;

        // Text with info links at the bottom of first step modal view
        scope.helpText = filter("translate")("svb.authentication.helpLinks", {
            infoLink: '<a href="' + links.info.href + '" target="_blank">{{\'' +
            links.info.key + '\' | translate}}</a>',
            supportLink: '<a href="' + links.support.href + '" target="_blank"' +
            '>{{\'' + links.support.key + '\' | translate}}</a>'
        });

        scope.helpTextUpdated = filter("translate")("svb.authentication.helpLinksUpdated", {
            infoLink: '<a href="' + links.info.href + '" target="_blank">{{\'' +
            links.info.key + '\' | translate}}</a>',
            supportLink: '<a href="' + links.support.href + '" target="_blank"' +
            '>{{\'' + links.support.key + '\' | translate}}</a>'
        });

        let textToReplace = "{{link}}";

        let cancelLink = '<a ng-click="cancel()" translate="svb.generic.clickHere"></a>';
        // Text with cancel link in the phone call step view
        scope.callCancelText = filter("translate")("svb.authentication.callCancel", {
            link: textToReplace
        }).replace(textToReplace, cancelLink);

        // Text with cancel link in the text message step view
        scope.textCancelText = filter("translate")("svb.authentication.textCancel", {
            link: textToReplace
        }).replace(textToReplace, cancelLink);

        // Text message view tooltip text
        scope.tooltipReviewText = filter("translate")("svb.authentication.reviewSecurityPreferences", {
            link: '<a href="'+configService.getConfig().eConnectUrl + links.security.href + '">{{\'' + links.security.key +
            '\' | translate}}</a>'
        });

		// Push message view tooltip text
		scope.tooltipReviewPush = filter("translate")("svb.authentication.reviewSecurityPreferencesForPush", {
			link: '<a href="'+configService.getConfig().eConnectUrl + links.security.href + '">{{\'' + links.security.key +
				'\' | translate}}</a>'
		});
    }

    let ctx = ModalService.prototype;

    ctx.beginOOBAWorkflow = (oobaId, secureUrlId, options) => {
        let defer = $q.defer();
        options = options || null;

        let _getCTVInfo = (paramId, paramSecureId) => {
            authenticationDataService.getCTVInfo(paramId).then(
                (res) => {
                    setStaticLinks($rootScope, $filter, ctvConstants); //to create the help text for helper links
                    return ctx.showAuthenticationModal(
                        res.data.data, //devices list
                        paramSecureId,  // secureURLId
                        null,  // enableAssist
                        defer, // defer
                        null, //referenceObjectHash
                        paramId, //oobaId
                        options
                    );
                },
                (res) => {
                    defer.reject(res);
                }
            );

        };

        if (oobaId === undefined || oobaId === null) {
            authenticationDataService.getOobaId().then(
                (paramOobaId) => {
                    _getCTVInfo(paramOobaId, secureUrlId);
                },
                (res) => {
                    defer.reject(res);
                }
            );
        } else {
            _getCTVInfo(oobaId, secureUrlId);
        }
        return defer.promise;
    };

    ctx.showAuthenticationModal = (data, secureURLId, enableAssist, defer, referenceObjectHash, oobaId, options) => {
        return openModal(
            _.assign({
                secureURLId: secureURLId,
                data: data,
                enableAssist: enableAssist,
                defer: defer,
                referenceObjectHash: referenceObjectHash,
                oobaId: oobaId
            }, options),
            "authenticate-modal",
            "modal-backdrop",
            TEMPLATE.AUTH_MODAL,
            "AuthenticationModalController"
        );
    };

    ctx.showAuthenticationErrorModal = () => {
        let contactText = $filter("translate")("svb.authentication.contactClientServices", {
            link: '<a href="' + ctvConstants.staticAuthenticationLinks.support.href + '" target="_blank">' +
            '{{\'svb.authentication.clientServices\' | translate}}' +
            '</a>'
        });

        return openModal({
                contactText: contactText
            },
            "mur-modal authenticate-modal auth-error-modal",
            "modal-backdrop",
            TEMPLATE.AUTH_ERROR_MODAL,
            "AuthenticationModalController");
    };

    ctx.showAuthenticationLogoutErrorModal = () => {
        let contactText = $filter("translate")("svb.authentication.failedToAuthenticateMax", {
            link: '<a href="' + ctvConstants.staticAuthenticationLinks.support.href + '" target="_blank">' +
            '{{\'svb.authentication.clientServices\' | translate}}' +
            '</a>'
        });

        return openModal(
            {contactText: contactText},
            "mur-modal authenticate-modal auth-error-modal",
            "modal-backdrop",
            TEMPLATE.AUTH_ERROR_LOGOUT_MODAL,
            "AuthenticationModalController");
    };

    ctx.showAuthenticationSuccessModal = (options) => {
        return openModal(options,
            "authentication-success-modal",
            "modal-backdrop",
            TEMPLATE.AUTH_SUCCESS_MODAL,
            "AuthenticationSuccessModalController");
    };

    return new ModalService();
};
