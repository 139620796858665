module.exports={
    "AED": "united-arab-emirates",
    "AFN": "afhani",
    "ALL": "lek",
    "AMD": "dram",
    "ANG": "guider",
    "AWG": "guider",
    "AOA": "kwanza",
    "ARS": "dollar",
    "AUD": "dollar",
    "AZN": "manat",
    "BAM": "convertible-marks",
    "BBD": "dollar",
    "BDT": "taka",
    "BGN": "bulgarian-lev",
    "BHD": "bahraini-dinar",
    "BMD": "dollar",
    "BND": "dollar",
    "BOB": "boliviano",
    "BRL": "brazillian-real",
    "BSD": "dollar",
    "BWP": "pula",
    "BYN": "ruble-ethiopia-birr",
    "BZD": "belizean-dollar",
    "CAD": "dollar",
    "CHF": "swiss-francs",
    "CNY": "yen",
    "COP": "dollar",
    "CRC": "colon",
    "CVE": "dollar",
    "CZK": "czech-republic-koruna",
    "DKK": "denmark-krone",
    "DOP": "dominican-peso",
    "DZD": "iraqi-dinar",
    "EGP": "pound",
    "ETB": "ruble-ethiopia-birr",
    "EUR": "euro",
    "FKP": "pound",
    "GBP": "pound",
    "GHS": "cedi",
    "GIP": "pound",
    "GTQ": "quetzal",
    "GYD": "dollar",
    "HKD": "hong-kong-dollar",
    "HNL": "lempira",
    "HTG": "haiti-gourde",
    "HUF": "hungarian-forint",
    "IDR": "indonesian-rupiah",
    "ILS": "israeli-shekel",
    "INR": "indian-rupee",
    "IQD": "iraqi-dinar",
    "ISK": "denmark-krone",
    "JMD": "jamaica-dollar",
    "JPY": "yen",
    "KGS": "uzbekistani-som",
    "KHR": "riel",
    "KRW": "won",
    "KYD": "dollar",
    "KZT": "tenge",
    "LAK": "kip",
    "LBP": "lebanese-pound",
    "LKR": "pakistan-rupee",
    "LRD": "dollar",
    "LSL": "lempira",
    "LYD": "libyan-dinar",
    "MGA": "malagasy-ariary",
    "MKD": "macedonia-denar",
    "MNT": "tugrik",
    "MOP": "pataca",
    "MUR": "pakistan-rupee",
    "MWK": "kwacha",
    "MXN": "dollar",
    "MYR": "malaysia-ringgit",
    "MZN": "metical",
    "NAD": "dollar",
    "NGN": "naira",
    "NOK": "denmark-krone",
    "NPR": "pakistan-rupee",
    "NZD": "dollar",
    "PAB": "panamanian-balboa",
    "PKR": "pakistan-rupee",
    "PLN": "poland-zloty",
    "PYG": "paraguay-guarani",
    "RON": "romania-new-leu",
    "RSD": "serbian-dinar",
    "SBD": "dollar",
    "SCR": "pakistan-rupee",
    "SHP": "pound",
    "SEK": "denmark-krone",
    "SGD": "dollar",
    "SLL": "leone",
    "SOS": "somali-shilling",
    "SRD": "dollar",
    "THB": "thai-baht",
    "TOP": "tongan-paanga",
    "TRY": "turkish-lira",
    "TTD": "trinidad-and-tobago",
    "TWD": "taiwan-new-dollar",
    "USD": "dollar",
    "UYU": "peso-uruguayo",
    "UZS": "uzbekistani-som",
    "VEF": "venezuela-bolivar",
    "VUV": "vatu",
    "WST": "dollar",
    "XCD": "dollar",
    "YER": "rial",
    "ZMW": "zambian-kwacha",
    "ZWL": "zimbabwean-dollar",
    "ZAR": "south-african-rand"
}