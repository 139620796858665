function cell($templateCache, $compile) {
    return {
        restrict: "E",
        scope: {
            templateUrl: "=templateUrl",
            row: "=?row",
            data: "=data",
            context: "=context",
            columnOptions: "=columnOptions"
        },
        compile: function () {
            return {
                pre: function ($scope, $elem) {
                    let template = $templateCache.get($scope.templateUrl);
                    let compiledElem = $compile(template)($scope);

                    $elem.append(compiledElem);
                }
            };
        }
    };
}

module.exports = angular
    .module('svb-table-cell', [
        require('angular-translate')
    ])
    .directive('svbCell', cell)
    .name;
