const {Viewport} = window;

function moduleCreator(angular) {
    function initModule($rootScope) {
        const viewport = Viewport.get();

        // Broadcast resize
        viewport.subscribe(data => {
            $rootScope.$broadcast('viewport:change', data);
        });

        // Broadcast breakpoint change
        viewport.subscribeBreakpoint(breakpoint => {
            $rootScope.$broadcast('viewport:breakpointChange', breakpoint);
        });

        // Broadcast stereotype change
        viewport.subscribeStereotype(stereotype => {
            $rootScope.$broadcast('viewport:stereotypeChange', stereotype);
        });

        // Broadcast orientation change
        viewport.subscribeOrientation(orientation => {
            $rootScope.$broadcast('viewport:orientationChange', orientation);
        });
    }

    initModule.$inject = ['$rootScope'];

    return module = angular.module('svb.viewport', []).run(initModule);
}

export default moduleCreator;