const _ = require('lodash');

const scopeVars = {
    options: '=options',
    label: '@label',
    label2: '@label2',
    value: '@value',
    fieldName: '@fieldName', /** Same as value */
    extraValues: "@extraValues",
    sort: '@sort', /** this is really orderBy **/
    filterName: '@filterName',
    class: '@class',
    valueClass: '@valueClass',
    cellTemplateUrl: '@templateUrl',
    headerTemplateUrl: '@headerTemplateUrl',
    tabindex: '@tabindex',
    comparator: '@comparator',
    disableSort: '@disableSort'
};

function ColumnController($parse, $scope) {

    this.getRawDisplayValueFromKey = function (model, key) {
        return $parse(key.trim())(model);
    };

    this.getRawValue = function () {
        return this.value;
    };

    this.getGridSize = function () {
        let classList = (this.class || "").split(" ");
        let gridClasses = _.filter(classList, function (item) {
            return item.indexOf("col-") === 0;
        });

        return gridClasses.join(" ");
    };

    this.getValueClass = function () {
        return this.valueClass;
    };

    this.onClicked = function () {
        let sortOrders = {
            'asc': 'desc',
            'desc': 'asc'
        };

        //  if sorting is already set for this column give the opposite if not set the original.
        this.sort = sortOrders[this.sort] || this.originalSort;
    };

    this.init = function () {
        $scope.$watch('chCtrl.sort', (newVal) => {
            if (newVal) {
                this.tableController.onSort(this);
            }
        });

        // Override attributes if there is options set
        // This is done to preserve backwards compatibility
        if (this.options) {
            Object.keys(scopeVars).forEach((key) => {
                if (key === 'options') {
                    return;
                }

                if (this.options.hasOwnProperty(key)) {
                    this[key] = this.options[key];
                }
            });

            this.value = this.fieldName || this.value;
            this.options.$controller = this;
        }

        // Keep original sort order
        this.originalSort = this.sort ? this.sort.toString() : 'asc';

        this.getDisplayValue = $parse(this.filterName ? this.value + ' | ' + this.filterName : this.value);
    };

    this.getClass = function () {
        return this.value.replace(/\./g, "-");
    };

    this.init();
}

function column() {
    return {
        restrict: "E",
        require: ['svbColumn', '^svbTable'],
        replace: true,
        scope: scopeVars,
        templateUrl: "core/modules/widgets/table/column/column.html",
        link: function ($scope, $element, $attrs, controllers) {
            let columnController = controllers[0];
            let tableController = controllers[1];

            columnController.tableController = tableController;
            tableController.addColumn(columnController);
        },
        controller: ['$parse', '$scope', ColumnController],
        controllerAs: 'chCtrl',
        bindToController: true
    };
}

module.exports = angular
    .module('svb-column', [
        require('angular-translate')
    ])
    .directive('svbColumn', column)
    .name;
