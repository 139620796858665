"use strict";

const moduleName = "svb-csrf-interceptor";

// CSRF doesn't apply to GET requests
// CSRF doesn't apply to authentication/authorization error responses
// Currently CSRF interceptor only applies to Billpay
function shouldExitCSRFInterceptor(config, status) {
    const isStatusOK = status && status !== 406;
    return config.method === "GET" || isStatusOK;
}

function csrfInterceptorFactory($injector, $q, $log) {
    return {
        'request': (config) => {

            if ($injector.get("configService").getConfig().doCSRF === false) {
                return config;
            }

            if (shouldExitCSRFInterceptor(config) || config.url.indexOf('/csrf/service/rest/csrf/v1/token') >= 0) {
                return config;
            }

            return $injector.get("csrfService").getToken().then((token) => {
                config.headers['X-ACCESS-TOKEN'] = token;
                return config;
            });
        },
        'response': (response) => {

            if ($injector.get("configService").getConfig().doCSRF === false) {
                return response;
            }

            if(response.config.url.indexOf('/csrf/service/rest/csrf/v1/token') >= 0) {
                return response;
            }
            // Angularjs constructs the response such that for a GET response, the header is directly on the response and for a POST it's inside config
            let tokenValue = response.headers('X-ACCESS-TOKEN');
            if (response.config.method !== 'GET' && !tokenValue) {
                // Permissive failure. This scenario is expected in the case of force logout for ooba failure.
                // Log it as an error, but continue on as normal
                if ($injector.get("urlService").getPropertyFromUrl(response.config.url, "doCSRF") !== false) {
                    $log.error(`CSRF token was not received for URL: ${response.config.url}`);
                }
            }
            if (tokenValue) {
                $injector.get("csrfService").setToken(tokenValue);
            }
            return response;
        },
        'responseError': (rejection) => {

            if ($injector.get("configService").getConfig().doCSRF === false) {
                return rejection;
            }

            let csrfInterceptorHelper;
            if (shouldExitCSRFInterceptor(rejection.config, rejection.status) ) {
                return $q.reject(rejection);
            }

            csrfInterceptorHelper = $injector.get("csrfInterceptorHelper");

            return csrfInterceptorHelper.tryWithNewToken(rejection);
        }
    };
}

angular.module(moduleName, []).factory("csrfInterceptor", ["$injector", "$q", "$log", csrfInterceptorFactory]);

module.exports = moduleName;