var moduleName = 'svb.ooba';

angular.module(moduleName, [
    require('angular-translate'),
    require('../services/servicesModule')
]).config(require('./locales/locale.js'))
    .constant('ctvConstants', require('./config/constants.js'))
    .factory('oobaModalService', require('./oobaModalService.js'))
    .factory('authenticationDataService', require('./authenticationDataService.js'))
    .controller('AuthenticationModalController', require('./authenticationModalController.js'))
    .controller('ModalController', require('./modalController.js'))
    .controller('AuthenticationSuccessModalController', require('./authenticationSuccessModalController.js'))
    .directive('svbCompile', require('./compileDirective'));

module.exports = moduleName;
