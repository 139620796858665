"use strict";

module.exports = ["$translateProvider", function ($translateProvider) {
    // Our translations will go in here
    $translateProvider
        .useSanitizeValueStrategy('sanitizeParameters')
        .translations('enUS', require("./enUS"))
        .translations('enUK', require("./enUK"))
        .translations('dev', require("./dev"))
        .preferredLanguage("enUS");
}];
