import * as currencyMap from './currencyMap.json';

// This component is in charge of displaying a currency, along with the currency symbol.
// It can also optionally display the currency ISO code and wrap the currency in parenthesis.
class CurrencyComponent {
	getCurrencyName(currency) {
		let currencyName = currencyMap[currency];

		if (currencyName) {
			return `svb-icon-currency-${currencyName}`;
		}

		return "";
	}

	_validateAmount(amount = 0) {
		if (typeof amount === 'string' && amount.indexOf(',') != -1) {
			return amount.replace(/,/g, "");
		}

		return amount;
	}

	formatAmount(amount) {
		return Math.abs(this._validateAmount(amount));
	}

	isNegative(amount) {
		return this._validateAmount(amount) < 0;
	}

	get shouldWrap() {
		return this.wrapWhenNegative && this.amount < 0;
	}
}

// Formats the currency amount correctly and prepends the appropriate currency symbol to it
export default angular
	.module('svb-currency-component', [])
	.component('svbCurrency', {
		restrict: "E",
		templateUrl: "core/modules/widgets/currency/currency.html",
		replace: true,
		bindings: {
			currency: "=",
			amount: "=?",
			customClass: "=?",
			showCurrencyCode: "<?",
			wrapWhenNegative: "=?"
		},
		controller: CurrencyComponent
	})
	.name;
