"use strict";

/**
 * Provide a way to style form elements such as input/select
 *  with a two column layout (input | help block) for desktop and expected mobile
 *  failover ui.
 *
 *  takes in transCluded elements, input, and help block
 *      - In the future, it can include more blocks on the right, such as warning
 *
 * @type {*[]}
 *
 * Usage Example:
 *
 *
 <svb-form-input help-title="Bank:"
                 help-detail="bankName"
                 show-help="showBankName"
                 help-class="warning"
                 help-link-url="http://link"
                 help-link-text="warning link text">
     <input-placeholder>
         <svb-input input-id="sort-code" name="payeeSortCode"
         ng-Model="value"
         required
         minlength="8"
         maxlength="8"
         disable-input="disableInput"
         numeric
         pay-validate-sort-code
         place-holder="payments.placeHolder.payeeSortCode"
         label="payments.makePayment.sortCode"
         filter-name="sortCode"
         on-blur="refreshBankName">
         </svb-input>
     </input-placeholder>
 </svb-form-input>
 */
module.exports = [function () {
    return {
        restrict: "E",
        scope: {
            helpClass: '=?',
            helpTitle: '=',
            helpDetail: '=',
            showHelp: '=',
            helpLinkUrl: '=?',
            helpLinkText: '=?'
        },
        transclude: {
            'inputPlaceholder': '?inputPlaceholder'
        },
        templateUrl: "core/modules/formElements/formInput/formInput.html"
    };
}];
