module.exports = function (navTabs) {

    global.displayCCBreadCrumbs = function () {
        var i;
        var currentChild;

        for (i = 0; i < navTabs.length; i++) {
            //Find accounts
            if (navTabs[i].properties.menuId === 1) {
                currentChild = navTabs[i];
                break;
            }
        }

        for (i = 0; i < currentChild.links.length; i++) {
            // Find Credit cards
            if (currentChild.links[i].properties.menuId === 129) {
                currentChild = currentChild.links[i];
                break;
            }
        }

        try {
            var breadCrumbStr = '<div class="new-econnect">' +
                '<div class="svb-header">' +
                '<div class="svb-header-section-navigation" data-svb-class="header-section-navigation">' +
                '<div class="svb-header-section-navigation-content" data-svb-class="header-section-navigation-content">' +
                '<ul data-svb-class="header-breadcrumbs-list" class="svb-header-breadcrumbs-list">' +
                '<li class="svb-last">My Credit Card</li>' +
                '</ul>' +
                '<ul role="navigation" class="svb-header-section-navigation-list" data-svb-class="header-section-navigation-list">';

            for (i = 0; i < currentChild.links.length; i++) {
                //If not payments
                if (currentChild.links[i].properties.menuId !== 132) {
                    breadCrumbStr += '<li><a href="#" onClick=contentPull("' + currentChild.links[i].href + '","Accounts","' + currentChild.links[i].properties.label + '",false)>' + currentChild.links[i].properties.label + '</a></li>';
                }
            }

            breadCrumbStr += '<div class="svb-header-section-navigation-overflow" data-svb-class="header-section-navigation-overflow" data-svb-class="header-section-navigation-overflow">' +
                '<div class="svb-header-section-navigation-overflow-active-page" data-svb-class="header-section-navigation-overflow-active-page"></div>' +
                '<span data-svb-class="header-section-navigation-overflow-link" class="svb-header-section-navigation-overflow-link">&raquo;<span></span></span>' +
                '<ul role="navigation" class="svb-header-section-navigation-overflow-list" data-svb-class="header-section-navigation-overflow-list"></ul>' +
                '</div>' +
                '</ul>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>';
        } catch (err) {
            alert(err.message);
        }

        $("span[id='ec_breadcrumbs']").append(breadCrumbStr);
		document.querySelector(".page-body-loader").classList.add("eC-loaded");
    };

    global.registerCard = function (link) {
        $.ajax({
            type: "POST",
            url: link,
            dataType: "html",
            success: function (responseText) {
                $('div.svb-content').append(responseText);
            },
            error: function () {
                $('div.svb-welcome-container').html('Sorry, there was an error processing your request. Please try again');
            }
        });
    };

    global.registerCardSuccess = function () {
        if ($("#creditCardsController").length > 0) {
            // If you are Murano Dashboard, reload the CC module
            angular.element('#creditCardsController').scope().reload();
        } else {
            // If you are on the EC CC module then reload the page
            window.location.reload();
        }
    };
};