"use strict";

var PromiseQueue = function () {
	return {
		queue: [],
		completed: 0
	};
};

var queueList = {
	fullPage: new PromiseQueue(),
	bodySection: new PromiseQueue()
};

var promiseService = function ($rootScope, $q, $log) {
	return {
		register: register,
		resolveQueue: resolveQueue,
		flush: flush,
		_getQueueList: getQueueList,
		_pollQueue: pollQueue,
		_promiseCompleted: promiseCompleted
	};

	function register(promise, queueName, skipOnFailure = false, hideSpinner = true) {
		if (!queueList.hasOwnProperty(queueName)) {
			queueList[queueName] = new PromiseQueue();
		}

		queueList[queueName].queue.push({promise, skipOnFailure, hideSpinner});

		// Start resolving the promise queue as soon as one is registered
		if (queueList[queueName].queue.length === 1) {
			this.resolveQueue(queueName);
		}

		return promise;
	}

	function resolveQueue(queueName) {
		if (!queueList[queueName]) {
			throw new Error("Queue with name: " + queueName + " does not exist");
		}

		if (queueList[queueName].queue.length === 0) {
			var deferred = $q.defer();
			deferred.resolve();

			return deferred.promise;
		}

		return this._pollQueue(queueName);
	}

	function promiseCompleted(queueName) {
		let queue = queueList[queueName].queue;

		queueList[queueName].completed++;

		if (queue.length === queueList[queueName].completed) {
			var deferred = $q.defer();
			deferred.resolve("COMPLETE");

			if (queue[queueList[queueName].completed - 1].hideSpinner) {
				this.flush(queueName, "Successful");
			} else {
				this.flush(queueName);
			}

			return deferred.promise;
		}

		return this._pollQueue(queueName);
	}

	function pollQueue(queueName) {
		var queue = queueList[queueName];

		if (queue.completed > queue.queue.length) {
			// This is caused by entering a state that doesn't exist
			// StateManager should account for all possible url matches
			$log.error('Redirect Error - Path is set incorrectly');
			this.flush(queueName, "Failed");
		} else {
			return queue.queue[queue.completed].promise.then(() => {
				return this._promiseCompleted(queueName);
			}).catch((error) => {
				$log.error('Promise failed: ', error);

				// This promise is not needed to paint the page
				if (queue.queue[queue.completed].skipOnFailure) {
					return this._promiseCompleted(queueName);
				}

				this.flush(queueName, "Failed");
			});
		}
	}

	function flush(queueName, status) {
		if (status) {
			$rootScope.$broadcast("PromiseQueue-" + status, queueName);
		}
		queueList[queueName] = new PromiseQueue();
	}

	function getQueueList() {
		return queueList;
	}
};

// This service is used to keep track of all the promises registered to this service. Once all the promises
// have resolved the service will broadcast an event, 'PromiseQueue-X', X is based on whether
// all the calls succeed or any calls fail.
module.exports = ["$rootScope", "$q", "$log", promiseService];
