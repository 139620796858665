/**
 * Created by spanneer on 3/14/16.
 */
var envConfig = { // NOSONAR (relax global variable rule)
    "staticAuthenticationLinks": {
        "info": {
            "key": "svb.generic.clickHere",
            "href": "https://www.svb.com/svbeconnect/resources/content/authenticate.html"
        },
        "support": {
            "key": "svb.authentication.supportCenter",
            "href": "https://www.svb.com/contactus.aspx"
        },
        "security": {
            "key": "svb.generic.clickHere",
            "href": "/auth/security/credentialChange.do?cmdCredentialChange=20"
        }
    },
    "authCode": {
        "badCode": {
            "bc13107": "13107",
            "bc13106": "13106"
        },
        "logout": "13110",
        "syserror": "-1"
    },
    "OOBA_CODES": {
        UK_PAYMENT: 107,
        BILLPAY_PAYMENT: 116
    }
};

module.exports = envConfig;