"use strict";

module.exports = function ($provide) {
    $provide.decorator('$locale', ['$delegate', '$translate', function ($delegate, $translate) {
        let value = $delegate.DATETIME_FORMATS;

        value.SHORTDAY = [
            $translate.instant('svb.day.shortSunday'),
            $translate.instant('svb.day.shortMonday'),
            $translate.instant('svb.day.shortTuesday'),
            $translate.instant('svb.day.shortWednesday'),
            $translate.instant('svb.day.shortThursday'),
            $translate.instant('svb.day.shortFriday'),
            $translate.instant('svb.day.shortSaturday')
        ];

        return $delegate;
    }]);
};