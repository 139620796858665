function pagination(directiveValidationService, $timeout) {
    return {
        restrict: 'E',
        scope: {
            totalItems: '=', // Binding for the total count of items
            currentPage: '=', // Binding for the current page number
            onChange: '&?   ', // Optional binding for a callback when the page changes
            itemsPerPage: '=?', // Optional binding for how many items to show per page. Defaults to 50
            maxSize: '<?' // Optional binding for the maximum number of pages to show. Defaults to 5
        },
        templateUrl: 'core/modules/widgets/pagination/pagination.html',
        link ($scope, $element, $attrs) {
            directiveValidationService.enforceRequiredAttributes('svbPagination', $attrs, 'totalItems', 'currentPage');

            if (!$scope.itemsPerPage) {
                $scope.itemsPerPage = 50;
            }

            if (!$scope.maxSize) {
                $scope.maxSize = 5;
            }

            $scope.onChangeWrapped = () => {
                $timeout(() => {
                    $scope.onChange();
                }, 0);
            };
        }
    };
}

export default angular
    .module('svb-pagination', [])
    .directive('svbPagination', pagination)
    .name;
