/**
 * SVB HTML Sanitizer. This is a replacements or angular-sanitize since angular-sanitize does not support customizations
 * and strips all SVB custom directives that we want to keep.
 * @module
 */

'use strict';

/**
 * SVB HTML Sanitizer Angular provider.
 */
module.exports = svbSanitizeProvider;

var _ = require('lodash');
var sanitizeHtml = require('sanitize-html');

var allowedTags = sanitizeHtml.defaults.allowedTags.slice();
var allowedAttributes = _.assign({}, sanitizeHtml.defaults.allowedAttributes);

// Extending default configuration
allowElement('*', ['id', 'name', 'ng-show', 'ng-hide']);
allowElement('span');
allowElement('a', ['ng-click', 'translate']);
allowElement('svb-link-dashboard', ['link-href', 'new-window', 'link-target', 'link-label', 'is-not-nav-link']);
allowElement('svb-post-link', ['post-url', 'form-data']);

function svbSanitizeProvider() {
    /* jshint validthis:true */

    /**
     * Service factory
     * @return {function} - the service function accepting {string} dirty HTML and returning {string} clean HTML
     */
    this.$get = function () {
        return svbSanitize;
    };

    /**
     * Add trusted element
     * @param {string} name - trusted element name. Use '*' to allow attributes for any element.
     * @param {string[]} attrs - optional trusted element attributes
     */
    this.allowDirective = allowElement;
}

function svbSanitize(html) {
    var cleanHtml = sanitizeHtml(html, {
        allowedTags: allowedTags,
        allowedAttributes: allowedAttributes
    });

    return cleanHtml;
}

function allowElement(name, attrs) {
    if (!name) {
        throw new ReferenceError('Trusted directive name must be specified');
    }

    var hasAttrs = Boolean(attrs && attrs.length);
    var anyName = (name === '*');

    if (!anyName && allowedTags.indexOf(name) < 0) {
        allowedTags.push(name);
    }

    if (hasAttrs) {
        if (!allowedAttributes[name]) {
            allowedAttributes[name] = [];
        }

        allowedAttributes[name] = _.union(allowedAttributes[name], attrs);
    }
}