"use strict";
var _ = require("lodash");

function DirectiveValidationService() { // NOSONAR
}

DirectiveValidationService.prototype = {
    enforceRequiredAttributes: function (identifier, attrs) {
        if (arguments.length < 3) {
            throw new Error("Invalid arguments to enforceRequiredAttributes.");
        }

        var requiredAttributes = _.toArray(arguments).slice(2);
        requiredAttributes.forEach(function (attrName) {
            if (angular.isUndefined(attrs[attrName])) {
                throw new Error("Directive with identifier " + identifier +
                    " is missing required attribute: " + attrName);
            }
        });
    }
};

module.exports = function () {
    return new DirectiveValidationService();
};