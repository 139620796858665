class TabPanelComponentController {
    constructor($attrs, directiveValidationService) {
        directiveValidationService.enforceRequiredAttributes('svbTabsPanel', $attrs, 'label');
    }

    $onInit() {
        this.svbTabs.addPanel(this);
    }

    /**
     * Checks if current panel is active
     * @returns boolean
     */
    isActive() {
        return this.svbTabs.panels.indexOf(this) === this.svbTabs.activePanelIndex;
    }

    /**
     * Returns index of current panel
     * @returns number
     */
    getIndex() {
        return this.svbTabs.panels.indexOf(this);
    }

    /**
     * Return unique id of current panel
     * @returns string
     */
    getPanelId() {
        return `${this.svbTabs.getId()}-panel-${this.getIndex()}`;
    }

    /**
     * Builds unique id of current tab
     * @returns string
     */
    getTabId() {
        return `${this.svbTabs.getId()}-tab-${this.getIndex()}`;
    }
}

TabPanelComponentController.$inject = [
    '$attrs',
    'directiveValidationService'
];

module.exports = {
    transclude: true,
    templateUrl: 'core/modules/widgets/tabs/tabsPanelComponent.html',
    controller: TabPanelComponentController,
    require: {
        svbTabs: '^svbTabs'
    },
    bindings: {
        label: '@'
    }
};