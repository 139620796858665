"use strict";

module.exports = ["$log", "$q", function ($log, $q) {
    return {
        /**
         * Called when a previous interceptor threw an error or resolved with a rejection
         * @param err error that got passed back
         * @return common error screen
         */
        responseError: (err) => {
            // Log and propagate the error
            let error = new Error(err.statusText);
            if(err.config && err.config.url) {
                $log.error("Request to '" + err.config.url + "' failed: " + error.stack);
            } else {
                $log.error("Request failed: " + error.stack);
            }


            //Need to reject in order to end the promise chain.
            return $q.reject(err);
        }
    };
}];