"use strict";

module.exports = ["configService", function (configService) {
    return {
        templateUrl: "core/modules/footer/footer.html",
        controller: ["$scope", "$stateParams", ($scope, $stateParams) => {
            $scope.$on('$stateChangeSuccess', () => {
                $scope.showFooter = ($stateParams.showFooter === undefined ? true : $stateParams.showFooter);
            });
        }],
        link: function (scope) {
            scope.staticFooterLinks = configService.getConfig().footerLinks;
			scope.currentDate = new Date();
        }
    };
}];