"use strict";

var moduleName = "svb-error-modal";

function directive() {
    return {
        restrict: "E",
        transclude: {
            'errorBody': '?errorBody' // Allows you to specify custom modal content
        },
        templateUrl: "core/modules/widgets/errorModal/errorModal.html"
    };
}

angular.module(moduleName, [
    require("../actionModal/actionModal"),
    require("angular-translate")
]).config(require("./locales/locale.js"))
    .directive("svbErrorModal", [
        directive
    ]);

module.exports = moduleName;