'use strict';
var moduleName = "svb-sort-code-filter";
var _ = require('lodash');

angular.module(moduleName, []).filter('sortCode', function () {
    return function (sortCode) {
        if (sortCode && _.isString(sortCode)) {
            var split = sortCode.match(/.{1,2}/g);
            return split ? split.join("-") : "";
        } else {
            return '';
        }
    };
});

module.exports = moduleName;
