const moduleName = "svb-secondary-auth-interceptor";

function secondaryAuthInterceptor($q, $injector) {
    return {
        responseError: (rejection) => {
            let oobaModalService = $injector.get("oobaModalService");

            if (rejection.status !== 401 || !rejection.data.meta || !rejection.data.meta.secondaryAuthRequired) {
                // This interceptor is not applicable
                return $q.reject(rejection);
            }

            let oobaId = rejection.data.meta.oobaId;
            let secureActionUrlId = rejection.data.meta.secureActionUrlId;

            if (oobaId && secureActionUrlId) {
                // Perform secondary authentication via ooba
                let retryConfig = angular.copy(rejection.config);

                if (!retryConfig.data.meta) {
                    throw new Error("Request payload does not found meta field pattern required by ooba");
                }

                retryConfig.data.meta.oobaId = oobaId;
                retryConfig.data.meta.secureActionUrlId = secureActionUrlId;

                return oobaModalService.beginOOBAWorkflow(oobaId, secureActionUrlId).then(
                    () => {
                        return $injector.get('$http')(retryConfig);
                    },
                    () => {
                        return $q.reject(rejection);
                    }
                );
            } else {
                throw new Error("Secondary auth interceptor didn't know how to authenticate");
            }
        }
    };
}

angular.module(moduleName, []).factory("secondaryAuthInterceptor", secondaryAuthInterceptor);

module.exports = moduleName;