module.exports = {
    "svb.day.shortSunday": "Su",
    "svb.day.shortMonday": "Mo",
    "svb.day.shortTuesday": "Tu",
    "svb.day.shortWednesday": "We",
    "svb.day.shortThursday": "Th",
    "svb.day.shortFriday": "Fr",
    "svb.day.shortSaturday": "Sa",
    "svb.datepicker.placeholder": "Choose Date",
    "svb.datepicker.placeholder.dateFormat": "MM/DD/YYYY"
};
